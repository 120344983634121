import { useLayoutEffect } from "react"
import { VisuallyHidden } from "@react-aria/visually-hidden"
import { motion, useAnimationControls } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useMenu } from "contexts/Menu"
import { useDictionary } from "contexts/Dictionary"
import { useLocale } from "contexts/Locale"
import getMediaQuery from "css/breakpoints"
import * as button from "css/buttons"
import * as text from "css/text"

import { statesLabel } from "components/menu/Pane"
import { Icon } from "components/icon/Icon"

const Switch = styled.button`
  ${button.buttonReset}
  ${text.legend100}
  ${text.normal}
  color:rgb(var(--pure-white));
  display: flex;
  align-items: center;
  opacity: 1;

  /* &:lang(ja),
  &:lang(ko),
  &:lang(zh-hans),
  &:lang(zh-hant) {
    word-break: keep-all;
  } */

  transition:
    color 300ms,
    fill 300ms;

  svg {
    margin-inline-end: 0.5rem;
  }

  @media (hover: hover) {
    &:hover {
      color: rgb(var(--ocean-green));
    }
  }
`

const Sub = styled(motion.div)`
  display: flex;
  align-items: center;
  grid-column: span 2 / -2;
  grid-row: 1;
  justify-self: end;

  &:lang(id) {
    grid-column: -3;
  }
`

export default function LangSwitch() {
  const console = useConsole()

  const dictionary = useDictionary()
  const menu = useMenu()
  const {
    current: { label: locale },
  } = useLocale()

  const anim = useAnimationControls()

  function onClick() {
    menu.langIsOpen.set(!menu.langIsOpen.get())
  }

  function onStateChange(state) {
    anim.start(state === statesLabel.closed ? "hide" : "show")
  }
  useLayoutEffect(() => menu.currentState.onChange(onStateChange))

  const variants = {
    hide: { opacity: 0, transition: { type: "tween" }, transitionEnd: { visibility: "hidden" } },
    show: { opacity: 1, visibility: "visible", transition: { type: "tween", duration: 0.2, delay: 0.36 } },
  }

  return (
    <Sub variants={variants} initial='hide' animate={anim}>
      <Switch onClick={onClick} aria-describedby='changeLanguage'>
        <Icon type='language' />
        {locale}
        <VisuallyHidden>
          <span id='changeLanguage'>{dictionary.changeLanguage()}</span>
        </VisuallyHidden>
      </Switch>
    </Sub>
  )
}
