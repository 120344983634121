export async function load(path) {
  return Promise.resolve(new URL(path, process.browser ? global.location.origin : process.env.REVERSE_PROXY_URL))
    .then(url => new Request(url))
    .then(request => fetch(request))
    .then(response => response.json())
    .then(body => {
      return [null, body]
    })
    .catch(err => [err, {}])
}

export const facetsMap = {
  alt_material: "material",
  "alt-steel-and-gold": "oystersteel",
  "alt-steel": "oystersteel",
  "alt-18-ct-yellow-gold": "yellow-gold",
  "alt-18-ct-pink-gold": "everose-gold",
  "alt-18-ct-white-gold": "white-gold",
  "alt-platinum": "platinum",
  "alt-titanium": "rlx-titanium",
  smooth: "smooth",
  fluted: "fluted",
  domed: "domed",
  "gem-set": "diamond-set",
  "black-ceramic": "cerachrom",
  "blue-ceramic": "cerachrom",
  "green-ceramic": "cerachrom",
  cerachrom: "cerachrom",
  "leather-strap": "leather",
  oyster: "oyster",
  president: "president",
  jubilee: "jubilee",
  oysterflex: "oysterflex",
  leather: "leather",
}

export function mapFeature(v) {
  switch (v) {
    case "alt_material":
      return "material"
    default:
      return v
  }
}

export function mapFacet(v, fam) {
  if (fam === "cosmograph-daytona" && v === "black-ceramic") {
    return v
  }
  switch (v) {
    case "alt-steel-and-gold":
    case "alt-steel":
    case "steel":
      return "oystersteel"
    case "alt-18-ct-yellow-gold":
    case "18-ct-yellow-gold":
      return "yellow-gold"
    case "steel-and-18-ct-yellow-gold":
      return "yellow-rolesor"
    case "steel-and-18-ct-pink-gold":
      return "everose-rolesor"
    case "steel-and-18-ct-white-gold":
      return "white-rolesor"
    case "alt-18-ct-pink-gold":
    case "18-ct-pink-gold":
      return "everose-gold"
    case "alt-18-ct-white-gold":
    case "18-ct-white-gold":
      return "white-gold"
    case "alt-platinum":
    case "platinum":
      return "platinum"
    case "alt-titanium":
    case "titanium":
      return "rlx-titanium"
    case "gem-set":
      return "diamond-set"
    case "leather-strap":
      return "leather"
    case "black-ceramic":
    case "blue-ceramic":
    case "green-ceramic":
      return "cerachrom"
    default:
      return v
  }
}

export function mapMatForAssets(v) {
  switch (v) {
    case "steel_and_18_ct_pink_gold":
    case "steel-and-18-ct-pink-gold":
      return "18-ct-pink-gold"
    case "steel_and_18_ct_yellow_gold":
    case "steel-and-18-ct-yellow-gold":
      return "18-ct-yellow-gold"
    case "steel_and_18_ct_white_gold":
    case "steel-and-18-ct-white-gold":
      return "18-ct-white-gold"
    case "green-ceramic":
      return "green-cerachrom"
    case "black-ceramic":
      return "black-cerachrom"
    case "blue-ceramic":
      return "blue-cerachrom"
    default:
      return v
  }
}

export function mapBracForTechCode(v, fam) {
  switch (`${v}-${fam}`) {
    case "diamond-set-president-day-date":
      return "diamond-set-president-dd36"
    case "diamond-set-president-lady-datejust":
      return "diamond-set-president-ldj"
    case "matt-black-1908":
    case "matt-brown-1908":
      return "leather"
    default:
      return v
  }
}

export function bezelFolder(v) {
  switch (v) {
    case "green-cerachrom":
    case "black-cerachrom":
    case "blue-cerachrom":
    case "blue-and-black-cerachrom":
    case "green-and-black-cerachrom":
    case "grey-and-black-cerachrom":
    case "red-and-blue-cerachrom":
      return "cerachrom"
    default:
      return v
  }
}

export function formatBezel(v, rmc) {
  if (v === "diamond-set" && rmc.includes("rbr")) {
    return `${v}-rbr`
  }
  if (v === "diamond-set" && rmc.includes("tbr")) {
    return `${v}-tbr`
  }
  switch (v) {
    case "green-ceramic":
      return "green-cerachrom"
    case "black-ceramic":
      return "black-cerachrom"
    case "blue-ceramic":
      return "blue-cerachrom"
    case "blue-and-black":
      return "blue-and-black-cerachrom"
    case "green-and-black":
      return "green-and-black-cerachrom"
    case "grey-and-black":
      return "grey-and-black-cerachrom"
    case "red-and-blue":
      return "red-and-blue-cerachrom"
    case "yellow-gold":
    case "white-gold":
    case "everose-gold":
      return "gold"
    default:
      return v
  }
}

export function formatStr(str) {
  return str.toLowerCase().replaceAll(" ", "-").replaceAll("_", "-").replaceAll(",", "")
}

export function getPath(str) {
  return `rolexcom/test/quick-views/${str}`
}

export function getNewPath(str, feat) {
  const p = {
    portrait: `catalogue/2024/configurator/quickview-${feat}-portrait/${str}`,
    landscape: `catalogue/2024/configurator/quickview-${feat}-landscape/${str}`,
  }
  return p
}

export function getMatTheme(tc) {
  switch (tc) {
    case "oystersteel":
      return "light-theme"
    default:
      return "dark-theme"
  }
}
export function getTheme(tc) {
  switch (tc) {
    case "":
      return "dark-theme"
    default:
      return "light-theme"
  }
}
