import styled from "@emotion/styled"
import { paths, points } from "./IconPath"

export function Icon({ type, className, size = 15, fill, ...rest }) {
  // List of icons that have to be mirrored in RTL
  const rtlIcons = ["play", "chevron", "externalLink", "reset", "arrowRight", "arrowLeft", "restart"]
  const rtlClass = rtlIcons.includes(type) && "rtl"
  const classNames = `${className || ""} ${rtlClass || ""}`.trim()

  return (
    <Svg
      className={classNames}
      height={size}
      width={size}
      viewBox='0 0 15 15'
      xmlns='http://www.w3.org/2000/svg'
      role='img'
      aria-hidden='true'
      alt=''
      fill={fill}
      {...rest}
    >
      {points[type] ? <polygon points={points[type]} /> : <path d={paths[type]} />}
    </Svg>
  )
}

const Svg = styled.svg`
  fill: ${props => (props.fill ? props.fill : "currentColor")};
  pointer-events: none;

  &.rtl:dir(rtl) {
    transform: scaleX(-1);
  }
`
