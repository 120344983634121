import { useEnv } from "contexts/Env"
import { useLocale } from "contexts/Locale"
import { memo, useEffect } from "react"
import { useDictionary } from "contexts/Dictionary"
import { useFrameAnimation, STEPS } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { mapFacet, formatStr, getNewPath, mapBracForTechCode } from "components/quickviews/utils"
import { useConfigurator } from "components/configurator-v3/context"

function mapMaterial(v) {
  switch (v) {
    case "steel":
    case "platinum":
    case "titanium":
    case "18_ct_white_gold":
    case "steel_and_18_ct_white_gold":
    case "steel_and_platinum":
      return "page-color-set-rolex-v7-color-007"
    case "steel_and_18_ct_yellow_gold":
    case "18_ct_yellow_gold":
      return "page-color-set-rolex-v7-color-004"
    case "18_ct_pink_gold":
    case "steel_and_18_ct_pink_gold":
      return "page-color-set-rolex-v7-color-001"
    default:
      return null
  }
}

function buildJourneyByStep(all, stepindex, o) {
  const updateJourneyState = (a, v) => {
    let vars = o[`variations_${v}`]
    let does_exist = !!vars
    let is_alone = does_exist && !~vars.indexOf(",")
    return !!a && !!a.length ? a : does_exist && !is_alone ? v : ""
  }

  let nextlist = all.slice(stepindex + 1)
  let prevlist = all.slice(0, stepindex)
  prevlist.reverse()

  return {
    prev: prevlist.reduce(updateJourneyState, "") || "",
    next: nextlist.reduce(updateJourneyState, "") || "",
  }
}

function buildJourney(all, item) {
  const res = {
    prev: [],
    next: [],
    sindex: [],
  }
  let journey = {}
  let sindex = 0
  let last = 0
  let next = 0

  all.map((v, i) => {
    journey = buildJourneyByStep(all, i, item)
    res.prev.push(all.indexOf(journey.prev))
    res.next.push(all.indexOf(journey.next))
  })
  //  console.log(item)
  if (item.has_3d_rendition) {
    let src = res.next.slice(0, -1).map(v => (v < 0 ? 6 : v))
    ////    console.log(item.rmc, src)
    let to = 0
    let ret = []
    do {
      ////      console.log(`${to},${src[to]}`)
      ret.push(`${to},${src[to]}`)
      //      if (src[to] < 6) ret.push(`${src[to]},${to}`)
      to = src[to]
    } while (to < 6)
    //    console.log(item.rmc, ret)
    res.paths = ret
  }

  res.sindex = res.next.map(v => {
    next = v > last ? sindex++ : sindex
    last = v
    return next
  })

  return { ...item, ...res }
}

function mapCatalog(all, a) {
  return a.map((v, i) => {
    const {
      familyCode,
      rmc,
      variations_model,
      variations_size,
      variations_material,
      variations_bezel,
      variations_bracelet,
      variations_dial,
      has_3d_rendition,
      part_config_diameter,
    } = v
    const {
      family,
      part_dial_file_name: dial_id,
      part_shadow_file_name: shadow_id,
      part_material_id,
      part_case_id,
      part_bracelet_id,
      spec_material: material,
      spec_bracelet: bracelet,
      spec_diameter: size,
      spec_bezel: bezel,
      spec_dial: dial,
      spec_case: description,
      name: familyName,
      name: model,
      newmodelselection,
      groupName,
      nameCode,
      color_set,
      facet_dial,
      spec_textblock,
      label_material_key,
      label_bezel_key,
      label_bracelet_key,
    } = v.watch.docs[0]

    const waProd = { productID: rmc, productCategory: familyCode, productModel: nameCode, productQuantity: 1 }

    const bezelTechCode = mapFacet(label_bezel_key, familyCode)
    const materialTechCode = mapFacet(label_material_key)
    const braceletTechCode = mapBracForTechCode(label_bracelet_key, familyCode)

    const bezelAsset = getNewPath(part_case_id, "bezel")
    const materialAsset = getNewPath(formatStr(part_material_id), "material")
    const braceletAsset = getNewPath(part_bracelet_id, "bracelet")

    return buildJourney(all, {
      rmc,
      family,
      part_config_diameter,
      familyCode,
      familyName,
      nameCode,
      description,
      variations_model: variations_model?.join(","),
      variations_size: variations_size?.join(","),
      variations_material: variations_material?.join(","),
      variations_bracelet: variations_bracelet?.join(","),
      variations_bezel: variations_bezel?.join(","),
      variations_dial: variations_dial?.join(",") || rmc,
      path3d: `${part_case_id}-${part_bracelet_id}`,
      model: groupName || model,
      newmodelselection,
      size,
      part_case_id,
      bracelet,
      part_bracelet_id,
      bezel,
      dial_id,
      dial,
      material_id: mapMaterial(part_material_id),
      material,
      shadow_id,
      has_3d_rendition,
      color_set,
      dial_filter: facet_dial,
      href: `/watches/${familyCode}/${rmc}`,
      spec_textblock,
      quickViews: {
        bezel: { assetPath: bezelAsset, techCode: bezelTechCode, topicLabel: bezel, assetId: part_case_id },
        material: { assetPath: materialAsset, techCode: materialTechCode, topicLabel: material },
        bracelet: { assetPath: braceletAsset, techCode: braceletTechCode, topicLabel: bracelet, assetId: part_bracelet_id },
        waProd,
      },
    })
  })
}

function mapFilters(data, dialfilters, all) {
  const filters = data?.filters?.[0]?.values
  if (!filters) return data
  dialfilters.current = [
    {
      value: "all",
      label: all,
    },
  ].concat(
    filters
      .filter(f => f.quantity > 0)
      ?.map(filter => ({
        value: filter.value,
        label: filter.localizedValue,
      }))
  )
  return data
}

export const CfgCatalog = memo(({ Ctx }) => {
  const { bkgfromrv } = useConfigurator()

  const { catalog, fetching, model, startrmc, updateStep, dialfilters, settled, step, launched } = useFrameAnimation(Ctx)
  const locale = useLocale()
  const dictionary = useDictionary()
  const env = useEnv()

  async function loadCatalog(v) {
    if (!v) return
    let q = new URL(
      `/api/catalog/config-2d/global?family=${v}&language=${locale.current.codes.www}`,
      process.browser ? /*"https://config-v3.dev.rlx-staging.com"*/ env.services : process.env.SERVICE_CATALOG_URL
    )
    /*    q = new URL(
          `/public/global.json`,
          process.browser ? "https://rolex.dev" : process.env.SERVICE_CATALOG_URL
        )*/
    catalog.current = await fetch(new Request(q))
      .then(response => response.json())
      .then(data => mapFilters(data, dialfilters, dictionary.all()))
      .then(data => mapCatalog(STEPS, data.results))

    //    model.set(startrmc)
    //    if (launched.get())
    fetching.set(null)
    if (settled.get() === 1) {
      model.set(startrmc)
      bkgfromrv.set("")
      updateStep()
    }

    //    if (startstep) {
    //    model.set(startrmc)
    //    updateStep()
    //    }
    //    updateStep()
  }

  function kill() {
    catalog.current = null
  }

  useEffect(() => kill)

  useEffect(() => mvSubscribe(fetching, loadCatalog), [])

  return null
})
