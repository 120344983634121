import { useRef, useState } from "react"
import styled from "@emotion/styled"
import { motion, AnimatePresence } from "framer-motion"
import parse from "html-react-parser"

import { useDictionary } from "contexts/Dictionary"

import { headline50, legend110, surtitle70 } from "css/text"
import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"

import { ReadMore } from "components/config-launcher/ConfigLauncher"
import Image from "components/media/ImageCLD"

const Card = styled(motion.section)`
  position: relative;
  height: 100%;
  ${fullGrid}
  align-items: end;

  ${getMediaQuery("m")} {
    grid-template-rows: unset;
  }

  & picture {
    display: contents;
  }
  & img {
    pointer-events: none;
    display: block;
    position: absolute;
    grid-column: doc;
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: center;
  }

  & h2 {
    ${headline50}
  }

  html.prefers-contrast & {
    & h2,
    & p {
      background: white;
      color: black !important;
    }
  }

  & input {
    opacity: 0;
    position: absolute;

    &.focus-visible + label button {
      outline: 3px solid rgb(var(--focus, 0 255 255) / 1);
      outline-offset: 0px !important;
    }
  }
`

const CardContent = styled.div`
  grid-column: main;
  grid-row: 2 / -1;
  color: rgb(var(--pure-white));

  padding-block-end: 10rem;

  &.dark-theme {
    color: rgb(var(--pure-white));
  }
  &.light-theme {
    color: rgb(var(--light-black));
  }

  position: relative;

  ${getMediaQuery("m")} {
    padding-block-end: unset;
    grid-column: col 1 / span 5;

    [dir="rtl"] & {
      grid-column: col -6 / span 5;
    }
  }
`

const Kicker = styled.p`
  ${surtitle70}
`

const Para = styled(motion.p)`
  ${legend110}
  padding-block-start: .25rem;

  &.show span + span {
    display: inline;
  }
  span + span {
    display: none;
  }

  ${getMediaQuery("m")} {
    span + span {
      display: inline;
    }
    padding-block: 1rem 10rem;
  }
`

export const Overlay = styled(motion.div)`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  .dark-theme & {
    background: rgba(0, 0, 0, 0.35);
  }
  .light-theme & {
    background: rgba(255, 255, 255, 0.35);
  }

  ${getMediaQuery("m")} {
    display: none;
  }
`

const RM = styled(ReadMore)`
  ${getMediaQuery("m")} {
    display: none;
  }
`

export default function LauncherCard({ asset, topicLabel, heading, paragraph, index, theme }) {
  const dictionary = useDictionary()

  const transformHtml = html =>
    parse(html, {
      replace: node => {
        if (node.name === "span") {
          return <span />
        }
      },
    })

  const parsedPara = Array.isArray(transformHtml(paragraph))

  const ref = useRef()

  const [show, setShow] = useState(false)

  const image = {
    alt: "",
    loading: "lazy",
    sizes: "100vw",
    sources: [
      {
        width: "3840",
        height: "2160",
        public_id: asset.landscape,
      },
      {
        width: "1560",
        height: "2600",
        public_id: asset.portrait,
        metadata: {
          v7_type: ["portrait"],
        },
      },
    ],
  }

  function onClick() {
    setShow(!show)
    ref.current.classList.toggle("show")
  }

  return (
    <Card className={theme} index={index}>
      <Image {...image} alt='' sizes='100vw' />
      <AnimatePresence>
        {show ? (
          <Overlay
            initial={{ opacity: 0, backdropFilter: "blur(0px) opacity(0)", WebkitBackdropFilter: "blur(0px) opacity(0)" }}
            animate={{ opacity: 1, backdropFilter: "blur(4px) opacity(1)", WebkitBackdropFilter: "blur(4px) opacity(1)" }}
            exit={{ opacity: 0, backdropFilter: "blur(0px) opacity(0)", WebkitBackdropFilter: "blur(0px) opacity(0)" }}
            transition={{ duration: 0.2, ease: "linear" }}
          />
        ) : null}
      </AnimatePresence>
      <CardContent className={theme}>
        {topicLabel ? <Kicker id={`apply-id-${index}`}>{topicLabel}</Kicker> : null}
        <h2 id={`qv-content-${index}`}>{heading}</h2>
        <Para ref={ref} dangerouslySetInnerHTML={{ __html: paragraph }} />
        {parsedPara ? <RM onClick={onClick}>{!show ? dictionary.readMore() : dictionary.readLess()}</RM> : null}
      </CardContent>
    </Card>
  )
}
