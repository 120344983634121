import React, { useLayoutEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { AnimatePresence, motion, useScroll } from "framer-motion"
import styled from "@emotion/styled"
import { FocusScope } from "@react-aria/focus"
import FocusTrap from "focus-trap-react"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"

import useScrollLock from "hooks/useScrollLock"

import generateComponents, { parseModularBlock } from "utils/generateComponents"

import Button from "components/button/Button"
import { usePopin } from "./PopinContextProvider"

import Image from "components/media/ImageCLD"

const Overlay = styled(motion.div)`
  ${fullGrid}
  position: fixed;
  inset: 0;
  z-index: calc(var(--z-top, 1000));
`

const Backerlay = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  inset: 0;
  background-color: rgb(var(--light-black) / 0.8);
`

const Root = styled(motion.div)`
  grid-column: doc;
  padding: 0;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${getMediaQuery("m")} {
    grid-column: main;
    &.full {
      grid-column: doc;
    }
  }
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: max-content;
  padding: 0;

  ${getMediaQuery("m")} {
    padding: 10vh 0;
    &.full {
      padding: 0;
    }
  }
`

const Content = styled.div`
  grid-row: 1;
  grid-column: 1;
  position: relative;

  ${getMediaQuery("m")} {
    border-radius: 30px;
    overflow: hidden;
    &.full {
      border-radius: 0px;
    }
  }

  & > * {
    width: 100% !important;
  }
`

const Figure = styled.figure`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`

const CloseBtn = styled(Button)`
  --spacing: calc(var(--outer-margin) - var(--height) / 2);

  ${getMediaQuery("m")} {
    --spacing: calc(var(--outer-margin) - var(--grid-gap) / 2 - var(--height) / 2);
  }

  ${getMediaQuery("xl")} {
    --spacing: calc((var(--outer-margin) - var(--grid-gap)) / 2 - var(--height) / 2);
  }

  position: sticky;

  margin-block-start: var(--spacing);
  margin-inline: auto var(--spacing);

  top: var(--spacing);
  grid-row: 1;
  grid-column: 1;

  z-index: calc(var(--z-top, 1000) + 2);
`

function PopCont({ label }) {
  const console = useConsole()

  const dictionary = useDictionary()
  const scrollLock = useScrollLock()

  const { onClose, components, background, dark_close_icon = false, fullscreen } = usePopin()

  let backgroundColor = ""

  if (!background[0]?.image_cld) {
    const bgs = background.reduce((acc, item) => new Map([...acc, ...Object.entries(item)]), new Map())
    const bgcol = bgs.has("color") ? bgs.get("color").colors.map(({ color, opacity }) => `rgb(${color} / ${opacity})`) : []
    backgroundColor = bgcol.length === 1 ? bgcol : `linear-gradient(${bgcol})`
  }

  const rootRef = useRef()
  const contentRef = useRef()

  const { scrollYProgress: scrollYMobile } = useScroll({ container: rootRef, target: contentRef })

  function handleCloseMobile() {
    onClose()
  }

  function onPanStart(_, { delta }) {
    if (scrollYMobile.get() >= 1 && delta.y < 0) {
      handleCloseMobile()
    }
  }

  useLayoutEffect(() => {
    const r = rootRef.current
    scrollLock.lock(r)
    return () => scrollLock.unlock(r)
  }, [])

  return (
    <Root
      className={`popin-edito${dark_close_icon ? " light-theme" : " dark-theme"} ${fullscreen ? " full" : " "}`}
      ref={rootRef}
      initial={{ y: "120vh" }}
      animate={{ y: 0 }}
      exit={{ y: "-100%" }}
      transition={{ duration: 0.6 }}
      role='dialog'
      aria-modal='true'
      aria-label={label}
      onPanStart={onPanStart}
    >
      <Container className={`${fullscreen ? "full" : " "}`}>
        <CloseBtn className={`icon translucent-${dark_close_icon ? "light" : "dark"}`} icon='close' onPress={onClose} aria-label={dictionary.popinClose()} />
        <Content className={`${fullscreen ? "full" : " "}`} ref={contentRef} style={{ background: backgroundColor }}>
          {generateComponents(parseModularBlock(components, { console }), { console })}
          {!!background[0]?.image_cld ? (
            <Figure>
              <Image {...background[0]?.image_cld} sizes='100vw' />
            </Figure>
          ) : null}
        </Content>
      </Container>
    </Root>
  )
}

export default function PopinEdito({ label }) {
  const { isOpen, onOverlayClick, onClose, content_in_english } = usePopin()
  const bodyElement = process.browser && document.body

  const csrAttrs = content_in_english ? { lang: "en", dir: "ltr" } : {}

  function onClick(e) {
    e.stopPropagation()
    onOverlayClick(e)
  }

  return (
    process.browser &&
    createPortal(
      <AnimatePresence>
        {isOpen && (
          <FocusTrap focusTrapOptions={{ onDeactivate: onClose }}>
            <Overlay
              initial={{ opacity: 0, backdropFilter: "blur(0px) opacity(0)", WebkitBackdropFilter: "blur(0px) opacity(0)" }}
              animate={{ opacity: 1, backdropFilter: "blur(4px) opacity(1)", WebkitBackdropFilter: "blur(4px) opacity(1)" }}
              exit={{ opacity: 0, backdropFilter: "blur(0px) opacity(0)", WebkitBackdropFilter: "blur(0px) opacity(0)" }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              {...csrAttrs}
            >
              <Backerlay onClick={onClick} />
              <PopCont label={label} />
            </Overlay>
          </FocusTrap>
        )}
      </AnimatePresence>,
      bodyElement
    )
  )
}
