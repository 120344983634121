import hash from "@emotion/hash"
import { useEnv } from "contexts/Env"
import { useLocale } from "contexts/Locale"
import { useUser } from "contexts/User"
import { useEffect, useLayoutEffect } from "react"

let adobeLaunchURL = ""
/* Description of the file
 This file is used to trigger the Adobe Launch event.

  The function waTriggerEvent is used to trigger the event.
  The function pushToDataLayer is used to push data to the dataLayer.
  The function fireActionTriggerCustomEvent is used to dispatch custom event to the browser.

  First of all the launchURL triggers the Adobe Launch script.
  The function load is used to load the script.

  See each function for further details.
 */

export const load = async function () {
  /* 
  This function is used to load the Adobe Launch script.
  It is used to load the script only once.
  */
  const launchURL = adobeLaunchURL
  const scriptId = "wa" + hash(launchURL)

  const node = (function () {
    if (document.getElementById(scriptId)) return scriptId
    return document.head.appendChild(Object.assign(document.createElement("script"), { src: launchURL, defer: true, async: true }))
  })()

  return (global[scriptId] ??= new Promise((resolve, reject) => {
    node.addEventListener("load", resolve, { once: true })
    node.addEventListener("error", reject, { once: true })
  })
    .then(() => new Promise(resolve => setTimeout(resolve, 300)))
    .catch(err => {}))
}

export const pushToDataLayer = (data, eventName, eventType, pageName) => {
  /*
  This function is used to push data to the dataLayer.
  The dataLayer is read by the Adobe Launch script.
  It is used to push data to the dataLayer only once.

  NB: In development mode, useEffect are run twice, so the dataLayer is pushed twice.
  */

  if (!process.browser) {
    // we don't want to push data to the dataLayer in SSR
    return
  }

  // get custom event from localStorage then remove custom event from localStorage
  const customEvent = localStorage.getItem("rlx_ddl_custom_event")
  localStorage.removeItem("rlx_ddl_custom_event")

  return load().then(() => {
    try {
      let statusCode = global.location.pathname.includes("/404") ? 404 : 200
      if (eventName) {
        /*
          In case of custom event, we want to push the event name to the dataLayer.
          So we get the last element of the dataLayer that has a page attribute, and we push the event name to the customEvents array.
        */
        const lastElement = global.digitalDataLayer[global.digitalDataLayer.length - 1]
        const lastElementCopy = structuredClone(lastElement)

        const lastElementDataCopy = structuredClone(lastElement.data)
        // items to keep from lastElementDataCopy
        const itemsToKeep = ["environment", "location", "page"]

        for (const key in lastElementDataCopy) {
          if (!itemsToKeep.includes(key)) {
            delete lastElementDataCopy[key]
          }
        }

        lastElementCopy.event = eventType
        lastElementCopy.data = { ...lastElementDataCopy, ...data }

        const customEvents = []
        if (Array.isArray(eventName)) {
          customEvents.push(...eventName)
        } else {
          customEvents.push(eventName)
        }
        if (customEvent) customEvents.push(customEvent)
        if (customEvents.length > 0) lastElementCopy.data.page.customEvents = customEvents

        lastElementCopy.data.page = { ...lastElementCopy.data.page }
        if (pageName) lastElementCopy.data.page.pageName = pageName //only for config special pageName

        lastElementCopy.data.environment.statusCode = statusCode //update statusCode
        global.digitalDataLayer.push(lastElementCopy)
      } else if (data.page === undefined) {
        /*
          Mostly the case of the initial dataLayer.
          We only have the variables contained at in the initialDataLayer
          (see the component's useEffect at the very bottom)
        */
        const lastElement = structuredClone(global.digitalDataLayer[global.digitalDataLayer.length - 1]) ?? { data: {} }
        const newData = { ...lastElement.data, ...data }
        newData.environment.statusCode = statusCode //update statusCode
        global.digitalDataLayer.push({ ...lastElement, event: eventType, data: newData })
      } else {
        /*
          In case of page event, we want to push the page attributes to the dataLayer.
          So we get the last element of the dataLayer that has a page attribute, and we push the page attributes to the dataLayer.
          We also want to get the initial attributes (environment and location) and push them to the dataLayer.
        */
        const lastElement = structuredClone(global.initialDigitalDataLayer)
        const newData = { ...lastElement.data, ...data }
        // add custom event to the page
        if (customEvent) {
          newData.page.customEvents = newData.page.customEvents || []
          newData.page.customEvents.push(customEvent)
        }
        newData.environment.statusCode = statusCode //update statusCode
        global.digitalDataLayer.push({ ...global.initialDigitalDataLayer, data: newData })
      }
    } catch (e) {
      console.error(e)
    }
  })
}

// function to dispatch custom event to the browser
export const fireActionTriggerCustomEvent = data => {
  if (process.browser) {
    document.dispatchEvent(new CustomEvent("event-action-trigger", { detail: data || {} }))
  }
}

export const fireViewEndCustomEvent = () => {
  const event = new CustomEvent("event-view-end")
  const obj = document.querySelector("body")
  obj.dispatchEvent(event)
}

export const storeCustomEvent = ({ eventName }) => {
  // add eventName to the localStorage to be used in the next page (rlx_ddl_custom_event)
  if (process.browser) {
    localStorage.setItem("rlx_ddl_custom_event", eventName)
  }
}

export const storeCustomRetailerInteraction = interaction => {
  if (process.browser) {
    localStorage.setItem("rlx_ddl_retailer_interaction", interaction)
  }
}

export const waTriggerEvent = ({ eventName, eventType, pageName, ...data }) => {
  /*
    This is the function called by the components to trigger events.
    It makes sure that the Adobe Launch script is loaded before pushing data to the dataLayer.
    It also makes sure that the initial dataLayer is pushed to the dataLayer before pushing data to the dataLayer via a timeout funcction.
    (see the send function)
  */

  if (!eventName) throw new Error(" WA_Trigger_Event : eventName is necessary")
  let tm = null

  const detailsCustomEvent = { ...data }

  function send() {
    if (process.browser && (document.location.hostname !== "www.rolex.com" || document.location.pathname !== "www.rolex.cn")) {
      // console.log("wawa", JSON.stringify(detailsCustomEvent, null, "\t"))
    }
    const ddlReady =
      process.browser &&
      global.digitalDataLayer &&
      global.digitalDataLayer.length > 0 &&
      global.digitalDataLayer[global.digitalDataLayer.length - 1]?.data?.location

    if (!process.browser || !global.digitalDataLayer) return

    clearTimeout(tm)
    if (!ddlReady) tm = setTimeout(send, 1000)
    else {
      pushToDataLayer(data, eventName, eventType, pageName)
      fireActionTriggerCustomEvent(detailsCustomEvent)
    }
  }
  load().then(() => {
    send()
  })
}

export default function DigitalDataLayer({ children, initialState, version }) {
  // const { version } = initialState || {}

  const { countryCode } = useUser()
  const locale = useLocale()
  const env = useEnv()
  adobeLaunchURL = env.adobeLaunchURL
  const handleBodyEvent = () => {
    const [lastpageload] = window.digitalDataLayer?.filter(o => o.event === "Page Load")?.reverse()
    if (!lastpageload) return
    window.digitalDataLayer.push(JSON.parse(JSON.stringify(lastpageload)))
    //   console.log("digitalDataLayer", global.digitalDataLayer)
  }

  useLayoutEffect(() => {
    document.body.addEventListener("quantcast:consent", handleBodyEvent)

    return () => {
      document.body.removeEventListener("quantcast:consent", handleBodyEvent)
    }
  })

  /*const siteName = `${env.cn ? "Rolex.cn" : "Rolex.com"} ${
    process.env.NODE_ENV === "development" ||
    global.location.host.includes(".rlx-staging.com") ||
    global.location.host.includes(".rlx-uat.com") ||
    global.location.host.includes("localhost")
      ? "SDK"
      : ""
  }`*/

  // useEffect(() => {
  // ignore wishlist because we want to send the event from the wishlist component with extra params
  if (process.browser) {
    // dataLayer, only FE, resets at every page
    const languageCode = locale?.current?.codes?.www
    global.digitalDataLayer ??= []
    global.initialDigitalDataLayer ??= {
      event: "Page Load",
      data: {
        environment: {
          environmentVersion: version, // todo: change version dynamically
          environmentName: env.waEnvironment,
          siteName: global.location.origin,
          statusCode: global.location.pathname.includes("/404") ? 404 : 200,
        },
        location: {
          countryCode: countryCode,
          languageCode: languageCode,
        },
      },
    }
  }
  // }, [])

  return children
}
