import { useRef } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { useConsole } from "contexts/Console"

import { bold } from "css/text"
import getMediaQuery from "css/breakpoints"

import Link from "components/link/Link"
import PopinButton from "components/popin-edito/PopinButton"

const gridPos = css`
  grid-column: main;
  grid-row: 2;
  align-self: end;
  justify-self: center;

  margin-block-start: 10vh !important;

  ${getMediaQuery("m")} {
    grid-column: col 4 / col 10;
  }
`

const Para = styled.p`
  ${bold}
  ${gridPos}
  text-align: center;
`

const LinkStyled = styled(Link)`
  ${gridPos}
  text-align: center;
`

const PopinStyled = styled(PopinButton)`
  ${gridPos}
`

const ctas = {
  link: LinkCTA,
  caption: Caption,
  popin: PopinCTA,
}

function LinkCTA({ data }) {
  const console = useConsole()

  return (
    <LinkStyled {...data} className='inline'>
      {data.label}
    </LinkStyled>
  )
}
function Caption({ data }) {
  const console = useConsole()

  return <Para>{data.content}</Para>
}
function PopinCTA({ data, className }) {
  const console = useConsole()

  return <PopinStyled {...data} icon='plus' style='inline reverseIcon' className={className} />
}

export default function CTATxt({ blocks }) {
  const console = useConsole()

  const CTAComp = ctas[Object.keys(blocks[0])[0]]

  return <CTAComp data={Object.values(blocks[0])[0]} />
}
