import { useContext, useLayoutEffect, useRef } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import getMediaQuery from "css/breakpoints"
import { buttonIcon, translucentDark } from "css/buttons"
import { Icon } from "components/icon/Icon"
import { mvSubscribe, observeResize, rafDelay, setProperty, isTouchEnabled } from "./../utils"

const SWAP_IN = css`
  transition: opacity 600ms cubic-bezier(0.61, 1, 0.88, 1) 100ms, visibility 0ms 0ms;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  //  will-change: opacity, visibility;
`

const Arrow = styled.button`
  position: relative;
  grid-row: 1/-1;
  grid-column: 1/-1;
  align-self: center;
  --arrow-size: 40px;
  ${buttonIcon}
  ${translucentDark}

  pointer-events: auto;
  display: none;
  border: 0;
  border-radius: 50%;

  width: var(--arrow-size);
  height: var(--arrow-size);

  transform: translateX(0%);
  transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1) 1.2s;

  ${SWAP_OUT}

  &.cfgf-arrow-active {
    ${SWAP_IN}
  }

  &.previous {
    justify-self: start;
    inset-inline-start: calc((var(--outer-margin) - var(--arrow-size)) / 2);
  }
  &.next {
    justify-self: end;
    inset-inline-end: calc((var(--outer-margin) - var(--arrow-size)) / 2);
  }
  ${getMediaQuery("m")} {
    --arrow-size: 44px;
    display: flex;
  }

  &.previous svg {
    transform: scale(-1);
  }
  &.previous:dir(rtl) svg {
    transform: scale(1);
  }
  &.next:dir(rtl) svg {
    transform: scale(-1);
  }
`

export default function Arrows(props) {
  const { ctx } = props
  const { rmfocus, focus, count } = useContext(ctx)
  const rfprev = useRef(null)
  const rfnext = useRef(null)

  function update(v) {
    rmfocus.set(focus.get() + v)
  }

  function onPrevious(e) {
    return update(-1)
  }
  function onNext(e) {
    return update(1)
  }

  function onFocus(v) {
    rfprev.current.classList[v >= 1 ? "add" : "remove"]("cfgf-arrow-active")
    rfnext.current.classList[v < count - 1 ? "add" : "remove"]("cfgf-arrow-active")
  }

  useLayoutEffect(() => mvSubscribe(focus, onFocus), [])

  return (
    <>
      <Arrow ref={rfprev} className='previous' onClick={onPrevious} aria-hidden='true' tabIndex='-1'>
        <Icon type={"moveNext"} />
      </Arrow>
      <Arrow ref={rfnext} className='next' onClick={onNext} aria-hidden='true' tabIndex='-1'>
        <Icon type={"moveNext"} />
      </Arrow>
    </>
  )
}
