import { useMemo, useRef } from "react"
import { motion, useInView } from "framer-motion"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"
import { slideUp } from "animations/slideUp"

import generateModularBlocks from "utils/generateModularBlocks"
import generateBackground from "utils/generateBackground"

import { getGradient, modularTexts } from "./common"

const pseudoStyles = css`
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 20vh;
`

const txtWidth = css`
  --txt-width: span 6;

  &.txt-start-s,
  &.txt-center-s,
  &.txt-end-s,
  &.txt-start-m,
  &.txt-center-m,
  &.txt-end-m,
  &.txt-start-l,
  &.txt-center-l,
  &.txt-end-l {
    --txt-width: span 6;
  }

  ${getMediaQuery("m")} {
    &.txt-start-s,
    &.txt-center-s,
    &.txt-end-s {
      --txt-width: span 8;
    }

    &.txt-start-m,
    &.txt-center-m,
    &.txt-end-m {
      --txt-width: span 10;
    }

    &.txt-start-l,
    &.txt-center-l,
    &.txt-end-l {
      --txt-width: span 10;
    }
  }

  ${getMediaQuery("l")} {
    &.txt-start-s,
    &.txt-center-s,
    &.txt-end-s {
      --txt-width: span 6;
    }

    &.txt-start-m,
    &.txt-center-m,
    &.txt-end-m {
      --txt-width: span 8;
    }
  }
`

const txtStart = css`
  --txt-start: col 1;

  &.txt-start-s,
  &.txt-center-s,
  &.txt-end-s,
  &.txt-start-m,
  &.txt-center-m,
  &.txt-end-m,
  &.txt-start-l,
  &.txt-center-l,
  &.txt-end-l {
    --txt-start: col 1;
  }

  ${getMediaQuery("m")} {
    &.txt-start-s,
    &.txt-start-m,
    &.txt-start-l {
      --txt-start: col 1;
    }

    &.txt-center-s {
      --txt-start: col 3;
    }
    &.txt-center-m {
      --txt-start: col 2;
    }
    &.txt-center-l {
      --txt-start: col 2;
    }

    &.txt-end-s {
      --txt-start: col 5;
    }
    &.txt-end-m {
      --txt-start: col 3;
    }
    &.txt-end-l {
      --txt-start: col 3;
    }
  }

  ${getMediaQuery("l")} {
    &.txt-start-s,
    &.txt-start-m,
    &.txt-start-l {
      --txt-start: col 1;
    }

    &.txt-center-s {
      --txt-start: col 4;
    }
    &.txt-center-m {
      --txt-start: col 3;
    }
    &.txt-center-l {
      --txt-start: col 2;
    }

    &.txt-end-s {
      --txt-start: col 7;
    }
    &.txt-end-m {
      --txt-start: col 5;
    }
    &.txt-end-l {
      --txt-start: col 3;
    }
  }
`

const Root = styled.section`
  ${fullGrid}
  ${txtWidth}
  ${txtStart}

  position: relative;

  ::before {
    ${pseudoStyles}
    top: 0;
    background: rgb(var(--top-color));
    mask-image: linear-gradient(black, transparent);
    -webkit-mask-image: linear-gradient(black, transparent);
  }

  ::after {
    ${pseudoStyles}
    bottom: 0;
    background: rgb(var(--bot-color));
    mask-image: linear-gradient(transparent, black);
    -webkit-mask-image: linear-gradient(transparent, black);
  }

  &.without-margin h2:not(:last-child) {
    margin-block-end: 0;
  }

  &.txt-justify-start {
    text-align: start;
  }
  &.txt-justify-center {
    text-align: center;
  }
  &.txt-justify-end {
    text-align: end;
  }
`

const Content = styled(motion.div)`
  grid-row: auto;
  z-index: 1;
  grid-column: var(--txt-start) / var(--txt-width);
  display: flex;
  flex-direction: column;

  ${modularTexts}
  h2,p {
    html.prefers-contrast & {
      color: black !important;
      -webkit-text-fill-color: inherit !important;
      position: relative;

      ::after {
        background: white;
        content: "";
        height: calc(100% + 30px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 20px);
        z-index: -1;
      }
    }
  }

  a + a {
    margin-block-start: 0.5rem;
    margin-inline-start: 0.5rem;
  }
`

export default function ArticleSimple({
  className,
  texts,
  background,
  horizontal_align,
  title_gradient,
  chapo_gradient,
  transition_gradient,
  text_width,
  text_justify,
}) {
  const console = useConsole()
  const contentRef = useRef()

  const modularBlocks = useMemo(() => generateModularBlocks(texts, { console }), [])

  const isInView = useInView(contentRef, { margin: "100% 0% 0% 0%" })
  const prefersReducedMotion = process.browser && document.documentElement.classList.contains("prefers-reduced-motion")
  const cssStyle = background ? generateBackground(background) : {}

  const titleGradient = title_gradient?.colors ? getGradient(title_gradient.colors) : null
  const chapoGradient = chapo_gradient?.colors ? getGradient(chapo_gradient.colors) : null

  const heading = texts[0]?.heading
  const chapo = texts[1]?.chapo?.text
  const withoutMargin = !!heading?.title && !!chapo ? "without-margin" : ""

  const txtWidthClass = `txt-${horizontal_align?.align ? horizontal_align.align : "start"}-${text_width?.width ? text_width.width : "s"}`
  const txtJustifyClass = `txt-justify-${!!text_justify ? text_justify : "start"}`

  return (
    <Root
      style={{
        ...cssStyle,
        "--title-gradient": titleGradient,
        "--chapo-gradient": chapoGradient,
        "--top-color": transition_gradient?.top_color,
        "--bot-color": transition_gradient?.bottom_color,
      }}
      className={`${className} ${txtWidthClass} ${txtJustifyClass} ${withoutMargin}`}
    >
      <Content variants={slideUp} initial='hidden' animate={prefersReducedMotion ? "show" : isInView ? "show" : "hide"} ref={contentRef}>
        {modularBlocks}
      </Content>
    </Root>
  )
}
