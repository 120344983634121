import { createContext, useContext, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import { useConsole } from "contexts/Console"
import Image from "components/media/ImageCLD"
import LauncherProvider from "./context"
import { useScroll, useMotionValue, useTransform, motion } from "framer-motion"
import { mvSubscribe, observeResize, rafDelay, setProperty, isTouchEnabled } from "./utils"
import Arrows from "./Arrows"
import Pagination from "./Pagination"
import { fullGrid } from "css/grid"
import { headline50, legend110 } from "css/text"
import Button from "components/button/Button"
import { useConfigurator } from "components/configurator-v3/context"
import ConfigFilters from "./ConfigFilters"
import { useDictionary } from "contexts/Dictionary"
import { clamp } from "css/text"
import { buttonReset } from "css/buttons"

const Main = styled.section`
  --cfgl-header-height: 0;
  position: relative;
  height: calc(100svh - var(--cfgl-header-height));
  ${fullGrid}
  user-select: none;
`

const List = styled.ul`
  grid-row: 1/-1;
  grid-column: doc;
  display: flex;
  min-height: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  overscroll-behavior-x: none;
  scroll-behavior: smooth;

  &.cfgl-straight {
    scroll-behavior: auto;
  }

  transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1) 0s;
  &.cfgl-resizing {
    pointer-events: none;
    scroll-behavior: auto;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(1, 1, 1, 1) 0s;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const Item = styled.li`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  flex: 0 0 100%;
  //  transform: translateZ(0);

  &:last-of-type {
    scroll-snap-align: end;
  }
`

export const Card = styled(motion.section)`
  position: relative;
  height: 100%;
  ${fullGrid}
  align-items: end;

  & picture {
    display: contents;
  }
  & img {
    pointer-events: none;
    display: block;
    position: absolute;
    grid-column: doc;
    grid-row: 1/-1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: center;
  }

  & h2 {
    ${headline50}
  }

  & p {
    ${legend110}
    padding-block: .25rem 1.25rem;

    ${getMediaQuery("m")} {
      padding-block: 1rem 2.87rem;
    }
  }

  html.prefers-contrast & {
    & h2,
    & p {
      background: white;
      color: black !important;
    }
  }

  & input {
    opacity: 0;
    position: absolute;

    &.focus-visible + label button {
      outline: 3px solid rgb(var(--focus, 0 255 255) / 1);
      outline-offset: 0px !important;
    }
  }
  /* > button {
    display: none;
  }
  ${getMediaQuery("m", { max: true })} {
    > button {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      padding: 0.5rem;
    }
  } */

  .launcherButtonDsk {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 0.5rem;
    /* ${getMediaQuery("m", { max: true })} {
      display: none;
    } */
  }
  .launcherButton {
    display: block;
    /* ${getMediaQuery("m", { min: true })} {
      display: none;
    } */
  }
`

export const CardContent = styled.div`
  grid-column: main;
  grid-row: 2 / -1;
  color: rgb(var(--pure-white));
  z-index: 2;
  text-rendering: optimizespeed;
  .dark-theme & {
    color: rgb(var(--pure-white));
  }
  .light-theme & {
    color: rgb(var(--light-black));
  }

  position: relative;
  padding: 6rem 0;

  ${getMediaQuery("m")} {
    grid-column: col 1 / span 5;
    margin-block-end: 5rem;

    [dir="rtl"] & {
      grid-column: col -6 / span 5;
    }
  }

  ${getMediaQuery("m", { max: true })} {
    padding: 6rem 0;
    /* > button {
      display: block;
    } */
  }
`

export const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: ${({ animation }) => (!animation ? "none" : "auto")};
  opacity: ${({ animation }) => (!animation ? 0 : 1)};
  transition: opacity 300ms ease-in-out;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  .dark-theme & {
    background: rgba(0, 0, 0, 0.35);
  }
  .light-theme & {
    background: rgba(255, 255, 255, 0.35);
  }

  ${getMediaQuery("m", { min: true })} {
    display: none;
  }
`

export const Div = styled.div`
  ${getMediaQuery("m", { max: true })} {
    margin-bottom: 2rem;
    /* transform: ${({ animation }) => (!animation ? "translateY(0%)" : "translateY(-45%)")};
    transform-origin: center;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out; */
  }
  will-change: transform opacity;
`

export const TextWrapper = styled.div`
  transition: opacity 400ms ease-in-out;
  ${getMediaQuery("m", { min: true })} {
    .btnContainer {
      > button {
        display: none;
      }
    }
  }
`

export const P = styled.p`
  ${getMediaQuery("m", { max: true })} {
    padding: 0 !important;

    span + span {
      display: ${({ animation }) => (!animation ? "none" : "inline")};
    }
  }
`

export const ReadMore = styled.button`
  display: none;

  ${getMediaQuery("m", { max: true })} {
    ${buttonReset}
    display: block;
    font-weight: 700;
    display: block;
  }
`

function LauncherCard(props) {
  const { ctx, image_cld, heading, param, index, theme, repos } = props
  const { count, focus, W, dir, maintheme } = useContext(ctx)
  const { route, launched, cta } = useConfigurator()
  const rfmain = useRef(null)
  const x = useMotionValue(0)
  const dictionary = useDictionary()
  const [animation, setAnimation] = useState(false)

  function update() {
    if (!W.get()) return x.set(0)
    let offset = Math.round((focus.get() - index) / count)
    x.set(dir * offset * count * W.get())
  }
  useLayoutEffect(() => mvSubscribe(focus, update), [])
  useLayoutEffect(() => mvSubscribe(W, update), [])

  function onFocus(v) {
    const state = (index % v) % count === 0
    //    console.log("onFocus", v, state, index, index % v, count)
    if (state && theme) maintheme.set(theme.theme)
    rfmain.current.setAttribute("aria-current", state)
  }
  useLayoutEffect(() => mvSubscribe(focus, onFocus), [])

  function onClick(e) {
    //    repos().then(() => {
    launched.set(true)
    route.set(param)
    cta.current = e?.target
    //    })
  }

  const [ready, setReady] = useState(false)

  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        ease: [0.32, 0.94, 0.6, 1],
      },
    },
  }

  const handleClick = () => {
    setAnimation(v => !v)
  }

  useLayoutEffect(() => {
    const img = rfmain.current.querySelector("img")
    if (!img || img?.complete) return onLoad()
    img.addEventListener("load", onLoad, { once: true })
    img.addEventListener("error", onLoad, { once: true })

    return () => {
      img.removeEventListener("load", onLoad, { once: true })
      img.removeEventListener("error", onLoad, { once: true })
    }

    function onLoad(e) {
      setReady(true)
    }
  }, [])

  return (
    <Card ref={rfmain} style={{ x }} variants={variants} initial='hidden' animate={ready ? "visible" : "hidden"} className={`_${theme?.theme ?? ""}`}>
      <Overlay animation={animation} onClick={() => setAnimation(false)} />
      <Image {...image_cld} alt='' sizes='100vw' />
      <CardContent>
        <Div animation={animation}>
          <TextWrapper animation={animation}>
            <h2 id={`familyName_${index}`}>{heading?.title}</h2>
            <P animation={animation} dangerouslySetInnerHTML={{ __html: heading?.paragraph }} />
            <ReadMore onClick={handleClick} animation={animation}>
              {!animation ? dictionary.readMore() : dictionary.readLess()}
            </ReadMore>
          </TextWrapper>
        </Div>
        <Button aria-describedby={`familyName_${index}`} className='launcherButton filled opaque-white-cfg' icon='none' onPress={onClick}>
          {dictionary.configure()}
        </Button>
      </CardContent>
    </Card>
  )
}

const RESIZEDELAY = 200
const RESTOREDELAY = 300
const REPOSDELAY = 300
const FREEZEDELAY = 200

function ConfigLauncherContents(props) {
  const { ctx, items, maintheme, heading, tabs, paragraph, chapo, className } = props
  console.log("ConfigLauncherContents", items)
  const { count, focus, rmfocus, x, W, dir, CLONEBY, frozen } = useContext(ctx)
  const l = (2 * CLONEBY + 1) * count
  const rflist = useRef(null)
  //  const rfloaded = useRef(false)
  const rfrepos = useRef(null)
  //  const rfresize = useRef(null)
  const resizing = useMotionValue(null)
  const rflastfocus = useRef(focus.get())
  const rfrestorefocus = useRef(null)

  const { scrollX } = useScroll({
    container: rflist,
    axis: "x",
    offset: ["start end", "end end"],
  })

  const index = useTransform([scrollX, W], ([scrollX, W]) => {
    scrollX = Math.abs(scrollX)
    x.set(scrollX)
    return W === 0 ? -1 : (scrollX / W + 0.5) >> 0
  })

  const rfdb = useRef(null)

  const rffreezing = useRef(null)
  function onResize(isize) {
    //    clearTimeout(rffreezing.current)
    //    freeze(true)
    //    console.log("CFGL onResize", isize, rflastfocus.current)
    rfrestorefocus.current = rfrestorefocus.current ?? rflastfocus.current
    W.set(isize)
    //    rffreezing.current = setTimeout(() => freeze(false), FREEZEDELAY)
    /*    clearTimeout(rfdb.current)
        resizing.set(isize === W.get() ? null : rflastfocus.current)
        rfdb.current = setTimeout(() => resize(isize), RESIZEDELAY)*/
  }
  useLayoutEffect(() => observeResize(rflist.current.parentNode, onResize), [])

  function onW(v) {
    //    console.log("CFGL onW", v)
    if (!v) return
    freeze(true)
    updateScroll(v * ((rfrestorefocus.current % count) + CLONEBY * count))
  }
  useLayoutEffect(() => mvSubscribe(W, onW), [])
  /*

    function resize(v) {
      console.log("CFGL resize", v)
      if (!resizing.get()) return
      rfdb.current = setTimeout(() => {
        const f = resizing.get() < 0 ? 0 : resizing.get()

        updateScroll(W.get() * ((f % count) + CLONEBY * count), true)
        resizing.set(null)
      }, rflastfocus.current < 0 ? 0 : RESTOREDELAY)
      W.set(v)
    }

    function onResize(isize) {
      console.log("CFGL onResize", isize)
      clearTimeout(rfdb.current)
      resizing.set(isize === W.get() ? null : rflastfocus.current)
      rfdb.current = setTimeout(() => resize(isize), RESIZEDELAY)
    }
    useLayoutEffect(() => observeResize(rflist.current.parentNode, onResize), [])
  */
  function onIndex(v) {
    console.log("CFGL onIndex", v, rflastfocus.current)
    if (v < 0) return
    focus.set(v)
    /*      rfdb.current = setTimeout(() => {
            rflastfocus.current = v
          }, 100)*/
  }
  useLayoutEffect(() => mvSubscribe(index, onIndex), [])

  function onFocus(v) {
    if (rfrestorefocus.current) return
    setTimeout(() => (rflastfocus.current = v), 100)
  }
  useLayoutEffect(() => mvSubscribe(focus, onFocus), [])

  function repos(v) {
    //    if (resizing.get()) return
    //    const state = v % W.get() < 1
    //    if (!state) return
    const nindex = (index.get() % count) + CLONEBY * count
    console.log("CFGL repos", v, index.get(), nindex)
    if (nindex === index.get()) return Promise.resolve()
    freeze(true)
    return new Promise(resolve => {
      updateScroll(W.get() * nindex).then(resolve)
    })
  }

  function onScrollX(v) {
    //    console.log("CFGL onScrollX", v)
    clearTimeout(rfrepos.current)
    //    if (resizing.get()) return
    if (rfrestorefocus.current) return
    //    const ended = v <= 0 || v >= (l - 1) * W.get()
    rfrepos.current = setTimeout(() => repos(v), REPOSDELAY)
  }
  useLayoutEffect(() => mvSubscribe(scrollX, onScrollX), [])

  const rffrozen = useRef(null)
  function freeze(v) {
    //    rffrozen.current = v
    rflist.current.classList[v ? "add" : "remove"]("cfgl-straight")
    if (!v) frozen.set(false)
  }

  function updateScroll(v) {
    //console.log("CFGL updateScroll", v)
    clearTimeout(rffreezing.current)
    return new Promise(resolve => {
      rflist.current.scrollLeft = dir * v
      rafDelay(resolve)
      rffreezing.current = setTimeout(() => {
        rfrestorefocus.current = null
        freeze(false)
      }, FREEZEDELAY)
    })
  }
  /*
      function onFrozen(v) {
        console.log("CFGL onFrozen", v)
        rflist.current.classList[v ? "add" : "remove"]("cfgl-straight")
      }
      useLayoutEffect(() => mvSubscribe(frozen, onFrozen), [])

      function onResizing(v) {
        v = focus.get() > 0 && !!v
        console.log("CFGL onResizing", v, focus.get())
        rflist.current.classList[v ? "add" : "remove"]("cfgl-resizing")
      }
      useLayoutEffect(() => mvSubscribe(resizing, onResizing), [])
    */
  function onRmfocus(v) {
    //console.log("CFGL onRmfocus", v)
    if (v === null) return
    freeze(frozen.get())
    updateScroll(W.get() * v)
    rmfocus.set(null)
  }
  useLayoutEffect(() => mvSubscribe(rmfocus, onRmfocus), [])

  return (
    <>
      <List ref={rflist} className='cfgl-straight'>
        {Array.from({ length: l }).map((v, i) => {
          const empty = i < CLONEBY * count || i >= (CLONEBY + 1) * count
          return empty ? (
            <Item key={`cfgl_${i}`} aria-hidden={true} />
          ) : (
            <Item key={`cfgl_${i}`}>
              <LauncherCard ctx={ctx} index={i} {...items[i - CLONEBY * count]} repos={repos} />
            </Item>
          )
        })}
      </List>
      <Arrows ctx={ctx} />
      <Pagination ctx={ctx} items={items?.map(v => v.heading)} />
    </>
  )
}

export default function ConfigLauncher(props) {
  const console = useConsole()
  const { tabs } = props
  console.log("tabs", tabs)
  const ctx = createContext(null)
  const [dir, setDir] = useState(0)
  const [touchenabled, setTouchenabled] = useState(-1)
  const [selected, setSelected] = useState(0)
  const rfmain = useRef(null)
  const rfdb = useRef(null)
  const { launched } = useConfigurator()
  const theme = useMotionValue(null)

  //  const rtl = process.browser ? window.location.search.indexOf("rtl") >= 0 : null

  useLayoutEffect(() => {
    launched.set(true)
    //    document.querySelector("html")?.setAttribute("dir", rtl ? "rtl" : "ltr")
    setTouchenabled(+isTouchEnabled())
    setDir(document.querySelector("html")?.getAttribute("dir") === "rtl" ? -1 : 1)
  }, [])

  useLayoutEffect(() => {
    window.addEventListener("resize", onResize, false)
    window.addEventListener("orientationchange", onResize, false)
    resize()

    return () => {
      window.addEventListener("resize", onResize, false)
      window.removeEventListener("orientationchange", onResize, false)
    }

    function resize() {
      setProperty(rfmain.current, "--cfgl-header-height", `${document.querySelector("header").getBoundingClientRect().height}px`)
    }

    function onResize(e) {
      clearTimeout(rfdb.current)
      rfdb.current = setTimeout(resize, 0)
    }
  }, [])

  function onTheme(v) {
    if (!v) return
      ;["dark-theme", "light-theme"].forEach(v => rfmain.current.classList.remove(v))
    rfmain.current.classList.add(v)
  }
  useLayoutEffect(() => mvSubscribe(theme, onTheme, false), [])

  return (
    <Main ref={rfmain}>
      <ConfigFilters tabs={tabs} update={setSelected} selected={selected} />
      {touchenabled >= 0 && dir !== 0 ? (
        <LauncherProvider
          Ctx={ctx}
          key={`cfgl-tab-${selected}`}
          count={tabs[selected].cards?.card?.length}
          CLONEBY={2}
          dir={dir}
          touchenabled={touchenabled}
          maintheme={theme}
        >
          <ConfigLauncherContents ctx={ctx} items={tabs[selected].cards?.card} {...props} />
        </LauncherProvider>
      ) : null}
    </Main>
  )
}
