import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Price from "components/price/Price"
import WishlistButton from "components/wishlist-button/WishlistButton"
import getMediaQuery from "css/breakpoints"
import { bold, body250 } from "css/text"
import { motion, useAnimationControls, useTransform, useMotionValue, useDragControls } from "framer-motion"
import { memo, useEffect, useRef, useState, useLayoutEffect } from "react"
import { useEnv } from "contexts/Env"
import { useFrameAnimation, STEPS } from "./FrameAnimation"
import { INSTALL_DURATION, INSTALL_WAIT, EMPTY_STRING } from "./constants"
import { FocusScope } from "@react-aria/focus"
import { cssSwap, fmSwap, mvSubscribe, rafDelay, setProperty, observeResize } from "./utils"
import Image from "components/media/ImageCLD"
import Button from "components/button/Button"
import Link from "components/link/Link"
import { buttonFilled, opaqueWhite, translucentDark, opaqueGreen, opaqueBlack, translucentLight } from "css/buttons"
import { useMediaLoader } from "./utils"
import { Icon } from "components/icon/Icon"
import { useDictionary } from "contexts/Dictionary"
import { useNavigation } from "contexts/Navigation"
import { useConfigurator } from "./context"

const SWAP_IN = css`
  transition:
    opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 40ms,
    visibility 0ms 0ms;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
//  will-change: opacity, visibility;
`

const Wishlist = styled.div`
  --offset: calc(1.875rem + 1.25rem);
  ${getMediaQuery("s")} {
    --offset: calc(3.125rem + 1.375rem);
  }
  position: relative;
  z-index: 4;
  //  padding: var(--offset) var(--outer-margin) 0;
  box-sizing: border-box;
  flex: 1;
  align-items: start;
  display: flex;
  //  overflow: hidden;
  //  pointer-events: none;

  & button {
    //    pointer-events: auto;
    transform: translateY(-50%);
  }

  .wishlist-button-variations {
    transform: none;
  }

  .wishlist-button-root {
    //    margin-block-start: -1rem;
  }

  &.wv_reveal {
    ${SWAP_IN}
  }
`

const Overlay = styled.div`
  position: relative;
  z-index: 30;
  height: 100%;
  grid-row: 1;
  grid-column: 1;
  background: rgba(0, 0, 0, 0.4);

  ${SWAP_OUT}

  &.wv_reveal {
    ${SWAP_IN}
  }
`

const TRANSITION_DURATION = 400

const Main = styled.section`
  --spacing: calc(var(--outer-margin) - var(--grid-gap) / 2 - var(--btn-height) / 2);
  padding-inline: var(--outer-margin) calc(var(--outer-margin) + var(--spacing));
  ${getMediaQuery("m")} {
    --spacing: calc(var(--outer-margin) / 2 - var(--btn-height) / 2);
    padding-inline: var(--outer-margin);
  }
  ${SWAP_OUT}
  transform: translateY(100%);
//  will-change: opacity, visibility, transform;

  &.wv_reveal {
    ${SWAP_IN}
    transform: translateY(0%);
    transition:
      opacity ${TRANSITION_DURATION}ms cubic-bezier(0.61, 1, 0.88, 1) ${INSTALL_DURATION + INSTALL_WAIT - TRANSITION_DURATION}ms,
      transform ${TRANSITION_DURATION}ms cubic-bezier(0.61, 1, 0.88, 1) ${INSTALL_DURATION + INSTALL_WAIT - TRANSITION_DURATION}ms,
      visibility 0ms 0ms;
  }
  &.cfg-straight {
    transition:
      opacity ${TRANSITION_DURATION}ms cubic-bezier(0.61, 1, 0.88, 1),
      visibility 0ms 0ms;
  }
  position: relative;
  grid-row: 2;
  grid-column: 1;
  display: grid;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.3;
  z-index: 30;
  overflow: hidden;

  & .cfg-active-price {
    height: 1lh;
    p {
      align-items: center;
    }
  
  }

  & * {
    font-size: 14px;
  }

  //  pointer-events: none;

  & button {
    //    pointer-events: auto;
  }

  & > div {
    //    pointer-events: none;
    padding: 1lh 0 0;
    box-sizing: border-box;
    transition:
      opacity 0.4s cubic-bezier(1, 1, 1, 1) 0.1s,
      visibility 0s;
    min-height: 7lh;
    z-index: 1;
    ${getMediaQuery("s")} {
      min-height: 6lh;
      padding: 1lh 0 0;
    }
  }

  & > div > h2 {
    font-size: 16px;
    padding: 0;
    ${bold}

    ${getMediaQuery("m")} {
      font-size: 14px;
    }
  }

  & > div > h2,
  & > div > p,
  & .cfg-active-price {
    ${SWAP_OUT}

    &.wv_reveal {
      ${SWAP_IN}
      transition-duration: 600ms, 0ms;
      transition-delay: 200ms, 0ms;
    }
  }

  & > section {
    --cfg-active-offset: 100dvh;
    --specs-panel-height: 100dvh;
    position: absolute;
    z-index: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    transform: translateY(var(--cfg-active-offset));
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: var(--specs-panel-height);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    .wv_reveal & {
      transition:
        transform 0s cubic-bezier(0.61, 1, 0.88, 1) 0ms,
        background-color 0.3s cubic-bezier(0.61, 1, 0.88, 1) 200ms;
    }
    &.cfg-straight {
      transition: background-color 0.3s cubic-bezier(0.61, 1, 0.88, 1) 200ms;
    }

    ${getMediaQuery("m")} {
      --specs-panel-height: auto;
    }

    & .cfg-active-close {
      font-size: 14px;
      position: relative;
      z-index: 1;
      grid-row: 1/-1;
      grid-column: 1/-1;
      align-self: start;
      justify-self: end;
      inset-inline-end: var(--spacing);
      transform: translateY(calc((var(--cfg-active-inset-base) - 100%) / 2));
      ${opaqueBlack}
      & > svg {
        &:nth-of-type(1) {
          position: relative;
        }
        &:nth-of-type(2) {
          position: absolute;
          opacity: 0;
          fill: rgb(var(--light-black));
        }
        transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1);
      }
    }

    & > div {
      position: relative;
      grid-row: 1/-1;
      grid-column: 1/-1;
      //        pointer-events: none;
      display: grid;
      height: 100%;
      box-sizing: border-box;
      grid-template-rows: auto 1fr auto;
      justify-items: center;
      padding-inline: var(--outer-margin);
      padding-block-end: 30px;
      opacity: 0;
/*      transition:
        opacity 0.1s cubic-bezier(1, 1, 1, 1) 0s,
        visibility 0s 0.1s;*/
      visibility: hidden;

      & button {
        //            pointer-events: auto;
      }

      & section {
        grid-row: 2;
        grid-column: 1;
        justify-self: start;
        align-self: end;
        padding-block: 0 1.25rem;

        ${getMediaQuery("m")} {
          align-self: center;
          max-width: 30%;
          padding-block: 0 0;
        }

        & > h2 {
          ${body250}
        }
      }

      & .cfg-active-wishlist {
        grid-row: 1;
        grid-column: 1;
        justify-self: start;
        //        margin-top: 30px;
        /*
        & .wishlist-button-variations {
          // to override default button style in that case
          color: rgba(var(--green)) !important;

          svg {
            fill: rgba(var(--green)) !important;
          }

          @media (hover: hover) {
            &:hover {
              color: rgba(var(--light-black)) !important;

              svg {
                fill: rgba(var(--light-black)) !important;
              }
            }
          }
        }*/
      }

      & > figure {
        --specs-panel-figure-height: 60vh;
        --specs-panel-figure-offset: 0vh;
        margin: 0;
        padding: 0;
        grid-row: 1 / span 2;
        grid-column: 1;
        align-self: start;
        justify-items: center;
        height: var(--specs-panel-figure-height);
        display: flex;
        align-items: center;
        justify-content: center;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        transform: translateY(var(--specs-panel-figure-offset));

        ${getMediaQuery("m")} {
          --specs-panel-figure-height: 80vh;
        }

        & > img {
          user-select: none;
          height: 100%;
          width: auto;
          object-fit: cover;
          -webkit-user-drag: none;
          ${SWAP_IN}
        }

        & > svg {
          position: absolute;
          ${SWAP_OUT}
        }

        &.wv_loading {
          & > img {
            ${SWAP_OUT}
          }
          & > svg {
            ${SWAP_IN}
          }
        }
      }

      & .cfg-active-discover {
        position: relative;
        grid-row: 3;
        grid-column: 1;
        align-self: end;
        ${buttonFilled}
        ${opaqueGreen}
      }
    }
  }

  &.wv_expand {
    overflow: visible;
    & > div {
      opacity: 0;
      visibility: hidden;
      transition:
        opacity 0.2s cubic-bezier(0.61, 1, 0.88, 1) 0s,
        visibility 0s 0.2s;
    }

    & > section {
      visibility: visible;
      transform: translateY(0%);
      background-color: rgba(255, 255, 255, 1);
      transition:
        transform 0.4s cubic-bezier(0.61, 1, 0.88, 1) 0ms,
        background-color 0.2s cubic-bezier(0.61, 1, 0.88, 1) 0ms;

      & .cfg-active-close {
        --container: rgb(var(--grey) / 0.3);
        --text: rgb(var(--light-black));
        transform: translateY(var(--spacing));

        backdrop-filter: blur(5px);

        @media (hover: hover) {
          :hover {
            --container: rgb(243 243 243 / 0.5);
            --text: rgb(var(--light-black));
          }
        }
        & > svg {
          &:nth-of-type(1) {
            opacity: 0;
            transition: opacity 0.2s cubic-bezier(0.61, 1, 0.88, 1);
          }
          &:nth-of-type(2) {
            opacity: 1;
            transition: opacity 0.2s cubic-bezier(0.61, 1, 0.88, 1) 0.2s;
          }
        }
      }

      & > div {
        opacity: 1;
        visibility: visible;
        transition:
          opacity 0.4s cubic-bezier(1, 1, 1, 1) 0.2s,
          visibility 0s 0s;
      }
    }
  }
`

const Swiper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export function CfgActiveTitle({ Ctx, id }) {
  const { model, getModel, reset } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(null)
  const _label = useTransform(model, update)
  const rfmain = useRef()

  function update(m) {
    return getModel(m)?.familyName
  }

  function render() {
    setLabel(_label.get())
  }

  function display() {
    cssSwap(rfmain.current, "wv_reveal", render)
  }
  useEffect(() => mvSubscribe(_label, display), [])

  function kill() {
    setLabel(null)
  }
  useEffect(() => kill, [])

  return (
    <h2 ref={rfmain} id={id}>
      {label}
    </h2>
  )
}

const CfgActiveOverlay = memo(({ Ctx }) => {
  const { expcol } = useFrameAnimation(Ctx)
  const rfmain = useRef()

  function expand(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  useEffect(() => mvSubscribe(expcol, expand), [])

  function onClick(e) {
    expcol.set(0)
  }

  return <Overlay ref={rfmain} onClick={onClick} />
})

const CfgActivePrice = memo(({ Ctx, ariaDescribedBy }) => {
  const { model, updateModel } = useFrameAnimation(Ctx)
  const [rmc, setRmc] = useState(null)
  const rfmain = useRef()

  function update(v) {
    display(false)
    if (!v) return
    rafDelay(() => setRmc(v))
  }
  useEffect(() => mvSubscribe(model, update), [])

  function onPriceReady(rmc, data) {
    updateModel(rmc, data)
    cssSwap(rfmain.current, "wv_reveal")
  }

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function kill() {
    setRmc(null)
  }
  useEffect(() => kill, [])

  return (
    <div ref={rfmain} className='cfg-active-price'>
      {rmc && <Price key={rmc} rmc={rmc} onReady={onPriceReady} no_observer={true} scope={true} ariaDescribedBy={ariaDescribedBy} />}
    </div>
  )
})

const CfgActiveDescription = memo(({ Ctx }) => {
  const { model, getModel, reset } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(null)
  const _label = useTransform(model, update)
  const rfmain = useRef()

  function update(m) {
    return getModel(m)?.description
  }

  function render() {
    setLabel(_label.get())
  }

  function display() {
    cssSwap(rfmain.current, "wv_reveal", render)
  }
  useEffect(() => mvSubscribe(_label, display), [])

  function kill() {
    setLabel(null)
  }
  useEffect(() => kill, [])

  return <p ref={rfmain} dangerouslySetInnerHTML={{ __html: label }}></p>
})

const CfgActiveImage = ({ picture }) => {
  const rfmain = useRef()
  const ready = useMotionValue(false)
  useMediaLoader(ready, "CFGAS", rfmain, picture || [])

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_loading")
  }

  function onReady(v) {
    display(!v)
  }

  useEffect(() => mvSubscribe(ready, onReady), [])

  return (
    <figure ref={rfmain}>
      <Loader size={20} />
      <Image sources={picture} loading='eager' sizes='(min-width: 48rem) 33vw, 60vw' />
    </figure>
  )
}

const CfgActiveFeature = memo(({ Ctx }) => {
  const { model, getModel, reset, step } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(EMPTY_STRING)
  const _label = useTransform([model, step], update)
  const rfmain = useRef(null)

  function update([model, step]) {
    if (!model) return EMPTY_STRING
    const m = getModel(model)
    if (!m) return EMPTY_STRING
    if (step <= 0) step = m.next[0]
    return m?.[STEPS[step]]
  }

  function render() {
    setLabel(_label.get())
  }

  function display() {
    cssSwap(rfmain.current, "wv_reveal", render)
  }
  useEffect(() => mvSubscribe(_label, display), [])

  function kill() {
    setLabel(null)
  }
  useEffect(() => kill, [])

  return <p ref={rfmain} dangerouslySetInnerHTML={{ __html: label }}></p>
})

export const CfgActiveWishlist = memo(({ Ctx, className }) => {
  const { swapping, model, lastStep, getModel } = useFrameAnimation(Ctx)
  const [wmodel, setWmodel] = useState(null)

  const rfmain = useRef()
  /*
    function render(v) {
      if (v || !lastStep()) return display(false)
      rafDelay(() => display(true))
    }
  
    function display(state) {
      rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
    }
  
    useEffect(() => mvSubscribe(swapping, render, false), [])
  */
  function update(v) {
    //      if (!lastStep()) return
    let m = getModel(v)
    if (!m) return
    setWmodel({
      rmc: v,
      family: m.familyCode,
      modelName: m.model,
      newmodelselection: m.newmodelselection,
    })
  }

  useEffect(() => mvSubscribe(model, update), [])

  function kill() {
    setWmodel(null)
  }

  useEffect(() => kill, [])

  return (
    <Wishlist className={className} ref={rfmain}>
      {wmodel && <WishlistButton {...wmodel} className='wishlist-button-variations' aria_describedby='watchName' withLabel />}
    </Wishlist>
  )
})

function CfgActiveSpecs({ Ctx }) {
  const { model, getModel, wa, expcol, clear } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)
  const [watch, setWatch] = useState(null)
  const env = useEnv()
  const resizedb = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const dictionary = useDictionary()
  const navigation = useNavigation()
  const [btnLabel, setBtnLabel] = useState(dictionary["moreInformation"]())

  function onModel(v) {
    if (!v) return
    //    console.log("onMooooodel", getModel(v))
    setWatch(
      Object.assign({}, getModel(v), {
        picture: [
          {
            public_id: `catalogue/${env.catalogYear}/upright-bba-with-shadow/${v}`,
            version: 1,
            width: 800,
            height: 1180,
            policy: "t_v7-main-configurator",
          },
        ],
      })
    )
  }
  useEffect(() => mvSubscribe(model, onModel, false), [])

  function onResize(isize, bsize) {
    //    console.log("ok ok ok onResize")
    clearTimeout(resizedb.current)
    const parenth = rfmain.current.parentNode.offsetHeight
    resizedb.current = setTimeout(() => {
      setProperty(rfmain.current, "--cfg-active-offset", `${bsize - parenth}px`)
      setProperty(rfmain.current, "--cfg-active-inset-base", `${parenth}px`)
    }, 10)
  }
  useLayoutEffect(() => observeResize(rfmain.current, onResize), [])

  useLayoutEffect(() => {
    const observer = new MutationObserver(onObserve)
    const config = { attributes: true, childList: true, subtree: true }

    observer.observe(rfmain.current, config)

    return () => observer.disconnect()

    function onObserve() {
      onResize(null, rfmain.current.getBoundingClientRect().height)
    }
  }, [])

  function onPress(e) {
    expcol.set(1 - expcol.get())
  }

  function expand(state) {
    rfmain.current.classList.remove("cfg-straight")
    setExpanded(state)
    setBtnLabel(state ? dictionary.popinClose() : dictionary["moreInformation"]())
  }
  useLayoutEffect(() => mvSubscribe(expcol, expand, false), [])

  function onDiscover(e) {
    e.preventDefault()
    wa({ configuratorCompleted: "true" })
    navigation.navigate(e.target?.getAttribute("href"))
    setTimeout(() => {
      clear()
    }, 400)
  }

  return (
    <FocusScope contain={expanded}>
      <section ref={rfmain} className='cfg-active-modal light-theme cfg-straight'>
        <div
          aria-hidden={!expanded}
          aria-modal={true}
          role="dialog"
          aria-labelledby="cfg-active-modal-heading"
          aria-describedby="cfg-active-modal-content"
        //          onKeyDownCapture={onKeyDown}
        >
          {watch && (
            <>
              <CfgActiveImage picture={watch.picture} />
              <section id="cfg-active-modal-content">
                <CfgActiveWishlist className='cfg-active-wishlist' Ctx={Ctx} />
                <CfgActiveTitle Ctx={Ctx} id="cfg-active-modal-heading" />
                <p dangerouslySetInnerHTML={{ __html: watch.spec_textblock?.replace?.(/\n/gi, "<br>") }} />
                <CfgActivePrice ariaDescribedBy="cfg-active-modal-heading" Ctx={Ctx} />
              </section>
              <Link className={`cfg-active-discover`} icon='none' href={watch.href} onClick={onDiscover}>
                {dictionary["discover"]()}
              </Link>
            </>
          )}
        </div>
        <Button icon='none' className='icon cfg-active-close' onPress={onPress} aria_label={btnLabel}>
          <Icon type='plus' />
          <Icon type='close' />
        </Button>
      </section>
    </FocusScope>
  )
}

function Loader({ size, color = "#808284" }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
      {[
        {
          rotate: "rotate(0 50 50)",
          begin: "-0.9166666666666666s",
        },
        {
          rotate: "rotate(30 50 50)",
          begin: "-0.8333333333333334s",
        },
        {
          rotate: "rotate(60 50 50)",
          begin: "-0.75s",
        },
        {
          rotate: "rotate(90 50 50)",
          begin: "-0.6666666666666666s",
        },
        {
          rotate: "rotate(120 50 50)",
          begin: "-0.5833333333333334s",
        },
        {
          rotate: "rotate(150 50 50)",
          begin: "-0.5s",
        },
        {
          rotate: "rotate(180 50 50)",
          begin: "-0.4166666666666667s",
        },
        {
          rotate: "rotate(210 50 50)",
          begin: "-0.3333333333333333s",
        },
        {
          rotate: "rotate(240 50 50)",
          begin: "-0.25s",
        },
        {
          rotate: "rotate(270 50 50)",
          begin: "-0.16666666666666666s",
        },
        {
          rotate: "rotate(300 50 50)",
          begin: "-0.08333333333333333s",
        },
        {
          rotate: "rotate(330 50 50)",
          begin: "0s",
        },
      ].map(({ rotate, begin }, index) => (
        <g transform={rotate} key={index}>
          <rect x='46' y='2.5' rx='9.200000000000001' ry='0.5' width='8' height='15' fill={color}>
            <animate attributeName='opacity' values='1;0' keyTimes='0;1' dur='1s' begin={begin} repeatCount='indefinite' />
          </rect>
        </g>
      ))}
    </svg>
  )
}

export const CfgActive = memo(({ Ctx }) => {
  const { model, step, settled, expcol } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const { headerize, unheaderize } = useConfigurator()

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }
  /*
    function onStep(v) {
      display(v > 0)
    }
    useEffect(() => mvSubscribe(step, onStep), [])
  */
  function onSettled(v) {
    display(v >= 0)
    if (v <= 0) setTimeout(() => rfmain.current?.classList.remove("cfg-straight"), 100)
  }
  useEffect(() => mvSubscribe(settled, onSettled), [])

  function onModel(v) {
    display(!!v)
  }
  //  useEffect(() => mvSubscribe(model, onModel), [])

  function expand(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_expand")
    if (state) headerize(document.querySelector(".cfgv7Modal"))
    else unheaderize(document.querySelector(".cfgv7Modal"))
  }
  useEffect(() => mvSubscribe(expcol, expand), [])

  function onSwap(e) {
    const target = e?.target
    if (!target) return
    let valid = target === rfmain.current || rfmain.current.contains(target)
    valid = valid && target.tagName !== "BUTTON"
    if (!valid) return
    expcol.set(1)
  }

  return (
    <>
      <CfgActiveOverlay Ctx={Ctx} />
      <Main ref={rfmain} className='light-theme cfg-straight' onClick={onSwap}>
        <div aria-live='polite' key={model}>
          <CfgActiveTitle Ctx={Ctx} id='watchName' />
          <CfgActiveFeature Ctx={Ctx} />
          <CfgActiveDescription Ctx={Ctx} />
          <CfgActivePrice Ctx={Ctx} ariaDescribedBy="watchName" />
        </div>
        <CfgActiveSpecs Ctx={Ctx} />
      </Main>
    </>
  )
})
