import { useEffect, useRef, useState } from "react"
import { motion, useReducedMotion, useMotionValueEvent, useTransform, useMotionTemplate, useMotionValue } from "framer-motion"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"

import Video, { cssVideo, cssContainer, cssPoster, cssFallback } from "components/media/VideoCLD"
import Image from "components/media/ImageCLD"

import { useValue } from "./context"
import TextBlock from "./texts/TextBlock"

const Li = styled(motion.li)`
  position: relative;
  justify-content: center;

  height: 200vh;
  margin-block-start: -100vh;

  z-index: 0;

  &:first-of-type {
    height: 100vh;
    margin-block-start: 0;
  }
  &:nth-of-type(2) {
    margin-block-start: 0;
  }
  &:last-of-type {
    height: 100vh;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-decoration: none;

  ${fullGrid}
  grid-template-rows: [top-row] 1fr 1fr [bottom-row];

  &:hover {
    ${({ cta }) => (cta ? "cursor: pointer" : "")};
  }
`

const containerStyle = css`
  height: 100%;
  width: 100%;
`

const MediaContainer = styled(motion.div)`
  height: 100vh;
  width: 100%;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  overflow: hidden;
  position: sticky;
  top: 0;
  pointer-events: none;
  margin-block-start: -100vh;
`

const VideoContainer = styled(motion.div)`
  ${containerStyle}

  & .${cssContainer} {
    z-index: -1;
    height: 100%;

    & .${cssVideo}, .${cssPoster}, .${cssFallback} {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const ImageContainer = styled(motion.div)`
  ${containerStyle}

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  background: #212121;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
`

const LizOver = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: rgba(255 0 0 / 0.5);
`

function VideoItem({ video, prog, index }) {
  const console = useConsole()

  const shouldReduceMotion = useReducedMotion()

  const videRef = useRef()

  const { input, inStopVid } = useValue()

  const vidPlay = useTransform([prog, input, inStopVid], ([p, i, o]) => p >= i[index] && p <= o[index])

  function onVidToPlay(bool) {
    if (shouldReduceMotion) return
    if (bool) {
      videRef.current.play()
    } else {
      videRef.current.pause()
    }
  }
  useMotionValueEvent(vidPlay, "change", onVidToPlay)

  return (
    <VideoContainer>
      <Video ref={videRef} autoPlay={index === 0} noButton autoPause={false} {...video} sizes='100vw' />
      {/* {index === 0 ? ( */}
      <Overlay initial={{ opacity: 1 }} animate={{ opacity: 0, transition: { delay: 0.5, duration: 0.9 } }} transition={{ type: "tween", duration: 1 }} />
      {/* ) : null} */}
    </VideoContainer>
  )
}

function ImageItem({ image, index }) {
  const console = useConsole()

  return (
    <ImageContainer>
      <Image {...image} sizes='100vw' />
      {index === 0 ? (
        <Overlay initial={{ opacity: 1 }} animate={{ opacity: 0, transition: { delay: 0.5, duration: 0.9 } }} transition={{ type: "tween", duration: 1 }} />
      ) : null}
    </ImageContainer>
  )
}

function AshlarContent({ heading, chapo, paragraph_blocks, media, cta, index, overlay }) {
  const console = useConsole()

  const { prog } = useValue()

  const isOverlay = overlay?.active && overlay?.color && overlay?.opacity > 0

  function onClick(e) {
    if (e.target.tagName === "BUTTON" || e.target.tagName === "A") return
    const btn = e.currentTarget.getElementsByTagName("button")
    const lnk = e.currentTarget.getElementsByTagName("a")
    const target = lnk[0] || btn[0]
    target?.click()
  }

  const insetInput = useMotionValue(100)

  const inset = useMotionTemplate`inset(${insetInput}vh 0 0 0)`

  const insetVal = useTransform(inset, ins => (index !== 0 && index !== 1 ? ins : "none"))

  return (
    <Wrapper className='dark-theme' onClick={onClick}>
      <MediaContainer style={{ clipPath: insetVal }}>
        {isOverlay ? <LizOver style={{ background: `rgba(${overlay.color} / ${overlay.opacity})` }} /> : null}
        {media[0]?.video_cld || media[1]?.video_cld ? (
          <VideoItem video={media[0]?.video_cld ?? media[1]?.video_cld} index={index} prog={prog} />
        ) : media[0]?.image_cld || media[1]?.image_cld ? (
          <ImageItem image={media[0]?.image_cld ?? media[1]?.image_cld} index={index} />
        ) : null}
      </MediaContainer>
      <TextBlock heading={heading} chapo={chapo} paragraph_blocks={paragraph_blocks} cta={cta} index={index} insetInput={insetInput} />
    </Wrapper>
  )
}

export default function Ashlar({ heading, chapo, paragraph_blocks, media, cta, uid, overlay, ...props }) {
  const console = useConsole()

  const [index, setIndex] = useState(null)

  const { pagesCount, updatePageNum, removePage, num } = useValue()

  const ref = useRef()

  useEffect(() => {
    updatePageNum(uid)
    setIndex(Array.from(ref.current.parentNode.children).indexOf(ref.current))
    return () => removePage(uid)
  }, [removePage, uid, updatePageNum])

  function onNumChange() {
    setIndex(Array.from(ref.current.parentNode.children).indexOf(ref.current))
  }
  useEffect(() => num.onChange(onNumChange))

  return (
    <Li ref={ref} style={{ zIndex: index === 0 ? pagesCount - index : index }} id={`ashlar--${index}`}>
      <AshlarContent heading={heading} chapo={chapo} paragraph_blocks={paragraph_blocks} media={media} cta={cta} index={index} overlay={overlay} />
    </Li>
  )
}
