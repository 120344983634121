import { useEffect, useLayoutEffect, useRef, memo, createContext } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe, rafDelay } from "./utils"
import { useTransform, useMotionValue } from "framer-motion"
import { useRollerAnimation, RollerAnimationProvider } from "./RollerAnimation"
import { CfgStepList } from "./dials.steplist"
import { CfgWatchRoller } from "./dials.watchroller"
import { SWAP_IN, SWAP_OUT, SWAP_OUT_DURATION } from "./constants"

const Main = styled.section`
  grid-row: 1/-1;
  grid-column: 1;
  --shadow-ratio: 1380 / 1180;
  transition: opacity 100ms cubic-bezier(0.61, 1, 0.88, 1) 0ms, visibility 0ms 100ms;

  ${SWAP_OUT}
  &.wv_appear {
    ${SWAP_IN}
//    transition-duration: 600ms, 0ms;
//    transition-delay: 100ms, 0ms;
    & ul {
      scroll-behavior: smooth;
    }
  }
  position: absolute;
  z-index: 20;
  left: 0;
  width: 100%;
  top: 50%;
  transform-origin: 50% 50%;
  //margin-top: var(--cfg-dial-offset);
  transform: translateY(calc((-50% + var(--cfg-dial-offset))  * var(--shadow-ratio)));

  display: flex;
  justify-content: center;
  pointer-events: none;

  height: var(--cfg-dial-shrink);
`

const CfgRollerContents = memo(({ ctx, rctx }) => {
  //console.log("CfgRollerContents reload")
  const { lastStep, rolling, swapping, step, modellist, dfilter, getModel, model, ready, beforelaststep, readyPanels } = useFrameAnimation(ctx)
  const { readyALL } = useRollerAnimation(rctx)
  const rfmain = useRef(null)
  const filteredlist = useMotionValue(null)
  let tmupdate = useRef(0).current

  const diallist = useTransform([modellist, dfilter], ([modellist, dfilter]) => {
    //    console.log("diallist dfilter", modellist, dfilter)
    if (!modellist) return null
    if (!dfilter || dfilter === "all") return `,${modellist}`
    return [dfilter].concat((modellist?.split(",")?.map(getModel)?.filter(v => v.dial_filter.includes(dfilter))?.map(v => v.rmc)))?.join(",")
  })

  function onreadyALL(v) {
    //    console.log("CfgRollerContents onreadyALL", v)
    if (!v || !lastStep()) return
    //    console.log("CfgRollerContents onreadyALL rolling false")
    display(true)
    rolling.set(false)
  }

  useLayoutEffect(() => mvSubscribe(readyALL, onreadyALL, false), [])

  function onStep(v) {
    //console.log("CfgRollerContents onStep", v)
    if (lastStep()) return
    rolling.set(false)
    filteredlist.set(null)
  }

  useLayoutEffect(() => mvSubscribe(step, onStep, false), [])

  function onSwapping(v) {
    //console.log("CfgRollerContents onSwapping", v)
    if (lastStep() && v) return display(false)
    display(lastStep() && readyALL.get())
  }

  function onFromLastStep(v) {
    //    if (!v) display(false)
  }

  function display(state) {
    //console.log("CfgRollerContents display", state)
    rfmain.current?.classList[state ? "add" : "remove"]("wv_appear")
  }

  function onDfilter(v) {
    if (!v) return filteredlist.set(null)
    display(false)
  }

  function onDiallist(v) {
    //    console.log("onDiallist", v)
    clearTimeout(tmupdate)
    if (!lastStep()) return rolling.set(false)
    if (!v) return
    //    v = v.split(",").slice(1).join(",")
    const oo = v.split(",").length <= 2
    rolling.set(oo ? !readyALL.get() : true)
    tmupdate = setTimeout(() => {
      if (dfilter.get()) {
        const [first] = v.split(",").slice(1)
        model.set(first)
      }
      filteredlist.set(v)
    }, SWAP_OUT_DURATION)
  }

  useLayoutEffect(() => mvSubscribe(swapping, onSwapping, false), [])
  useLayoutEffect(() => mvSubscribe(dfilter, onDfilter, false), [])
  useLayoutEffect(() => mvSubscribe(diallist, onDiallist, false), [])

  function onModel(v) {
    //    console.log("CfgRollerContents dials onModel", v)
    if (!v || !beforelaststep.get()) return
    filteredlist.set(v)
  }

  useLayoutEffect(() => mvSubscribe(model, onModel, false), [])

  return (
    <Main ref={rfmain}>
      <CfgStepList ctx={ctx} rctx={rctx} diallist={filteredlist} />
      <CfgWatchRoller ctx={ctx} rctx={rctx} diallist={filteredlist} />
    </Main>
  )
})

export const CfgRoller = memo(({ Ctx }) => {
  const { reset, resized } = useFrameAnimation(Ctx)
  const rollerCtx = createContext()

  return (
    <RollerAnimationProvider Ctx={rollerCtx} reset={reset} resized={resized}>
      <CfgRollerContents ctx={Ctx} rctx={rollerCtx} />
    </RollerAnimationProvider>
  )
})
