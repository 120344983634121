import { useRef } from "react"
import styled from "@emotion/styled"
import { motion, useScroll, useTransform } from "framer-motion"

import { useConsole } from "contexts/Console"
import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"
import { surtitle100, headline100 } from "css/text"

import Image from "components/media/ImageCLD"
import { kickerCN, subtitleCN } from "components/page-heading/style"

const Header = styled(motion.header)`
  position: relative;
  justify-content: center;
  ${fullGrid};
`

const Hgroup = styled(motion.hgroup)`
  --top-ofs: 15vh;
  ${getMediaQuery("m")} {
    --top-ofs: 15vh;
  }
  display: grid;
  grid-template-rows: [kicker subtitle] min-content [mid-line] min-content [title];
  grid-column: col 1 / span 6;
  text-align: center;
  height: fit-content;

  grid-row: 1;

  position: sticky;
  top: var(--top-ofs);
  padding-block-start: var(--top-ofs);

  ${getMediaQuery("m")} {
    grid-column: col 2 / span 10;
  }
  ${getMediaQuery("l")} {
    grid-column: col 3 / span 8;
  }

  & h1 {
    ${headline100}
    grid-row: mid-line / title;
  }

  & .${kickerCN} {
    ${surtitle100}
    grid-row: kicker / mid-line;
  }

  & .${subtitleCN} {
    ${surtitle100}
    grid-row: subtitle / mid-line;
  }
`

const Figure = styled(motion.figure)`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  grid-column: doc-start / doc-end;
  justify-content: center;

  margin-block-start: 10vh;
`

const ImgContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: end;
  transform-origin: top;

  margin-block-end: -5vh;

  ${getMediaQuery("m")} {
    align-items: unset;
  }

  & picture {
    display: flex;
  }

  & img {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
    transform-origin: top;

    transform: translate3d(0, 0, 0);

    ${getMediaQuery("m")} {
      width: 70%;
      max-width: 1200px;
    }
  }
`

export default function CoverFeatures({ heading, image_cld: image, background_color }) {
  const console = useConsole()

  const { title, kicker, subtitle } = heading

  const ref = useRef()

  const { scrollYProgress } = useScroll({ target: ref, offset: ["start start", "end start"] })

  const opacity = useTransform(scrollYProgress, [0, 0.05], [1, 0])

  const y = useTransform(scrollYProgress, [0, 1], ["-5%", "5%"])

  const bgcol = background_color.colors.map(({ color, opacity }) => `rgb(${color} / ${opacity})`).join(",")

  return (
    <Header
      ref={ref}
      className='page-title-block'
      animate='visible'
      initial='hidden'
      style={{ background: background_color.colors.length > 1 ? `linear-gradient(${bgcol})` : bgcol }}
    >
      <Hgroup style={{ opacity }}>
        {kicker ? <p className={kickerCN}>{kicker}</p> : null}
        {title ? <h1>{title}</h1> : null}
        {subtitle ? <p className={subtitleCN}>{subtitle}</p> : null}
      </Hgroup>
      <Figure>
        <ImgContainer style={{ y }}>
          <Image {...image} sizes='(max-width: 48rem) 100vw, min(70vw, 1200px)' />
        </ImgContainer>
      </Figure>
    </Header>
  )
}
