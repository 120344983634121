import {
  buildFacet,
  buildPager,
  buildResultsPerPage,
  buildResultTemplatesManager,
  buildSearchBox,
  buildSearchEngine,
  getOrganizationEndpoints,
  loadFieldActions,
} from "@coveo/headless"

import { buildProductRecommendationEngine } from "@coveo/headless/product-recommendation"
import { OverlayProvider } from "@react-aria/overlays"
import { enableContextDevTools as enableUFContextDevTools, Context as UFContext } from "@uniformdev/context"
import { UniformContext as UFReactContext } from "@uniformdev/context-react"
import DigitalDataLayer from "components/analytics/DigitalDataLayer"
import QuantCast from "components/analytics/QuantCast"
import * as components from "components/components"
import { ConfiguratorProvider } from "components/configurator-v3/context"
import Console from "components/console/Console"
import Dictionary from "components/dictionary/Dictionary"
import Env from "components/env/Env"
import Helmet from "components/helmet/Helmet"
import Layout from "components/layout/Layout"
import Locale from "components/locale/Locale"
import Navigation from "components/navigation/Navigation"
import SearchWrapper from "components/search/SearchWrapper"
import { StoryProvider } from "components/stories/context"
import Suspender from "components/suspender/Suspender"
import Theme from "components/theme/Theme"
import User from "components/user/User"
import Viewport from "components/viewport/Viewport"
import { PanelModalProvider } from "contexts/PanelModal"
import { Provider as WishlistProvider } from "contexts/Wishlist"
// import { SSRProvider } from "react-aria"
import { QuickViewsProvider } from "components/quickviews/context"
import reactDOM, { createRoot } from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from "src/reportWebVitals"
import camelCase from "utils/camelCase"

import "focus-visible"

const optionsForSearchBox = {
  options: {
    highlightOptions: {
      exactMatchDelimiters: {
        open: "<strong>",
        close: "</strong>",
      },
      correctionDelimiters: {
        open: "<i>",
        close: "</i>",
      },
    },
  },
}
global.UFContext = UFContext

global.accessTokens = {}

async function uniform() {
  return JSON.parse(document.querySelector("#__INITIAL_MANIFEST")?.textContent ?? null) ?? Promise.resolve({ project: {} })
}

async function p13n([initialState, akamai, ufManifest]) {
  const quirks = {
    "aka-loc-country": akamai.countryCode,
  }

  const sessionExpirationInSeconds = 1800
  const ufContext = new UFContext({
    defaultConsent: true,
    manifest: ufManifest,
    plugins: [enableUFContextDevTools()],
    visitLifespan: sessionExpirationInSeconds * 1000,
  })

  console.log("uniform:context:update(%o)", { manifest: ufManifest, visitLifespan: sessionExpirationInSeconds * 1000 })
  ufContext.update({ quirks })
  return [initialState, akamai, ufContext]
}

async function getCoveoSearchTokens(initialState) {
  try {
    const url = new URL(
      "/api/catalog/" + initialState.env.searchTokenRoute + "/" + initialState.env.searchTokenEndpoint,
      initialState?.env?.services || global.location.origin
    )
    const request = new Request(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${initialState.env.accessToken}`,
      },
      body: JSON.stringify([initialState.env.pipelineGrids, initialState.env.pipelineWebsearch, initialState.env.pipelineWebRecommendation]),
    })
    const response = await fetch(request)
    const body = await response.json()
    return [body[initialState.env.pipelineGrids], body[initialState.env.pipelineWebsearch], body[initialState.env.pipelineWebRecommendation]]
  } catch (err) {
    console.error(err)
    return new Array(4).fill(initialState.env.accessToken)
  }
}

async function coveo([initialState, akamai, ufContext]) {
  let resultResponse = []

  const getReferrer = () => {
    if (initialState?.env?.hostname) {
      return initialState.env.hostname
    }
    return initialState?.env?.statics?.includes("rolex.cn") ? "rolex.cn" : "rolex.com"
  }

  const [gridAccessToken, searchAccessToken, webRecommendationToken] = await getCoveoSearchTokens(initialState)

  global.accessTokens.pipelineWebsearch = searchAccessToken
  global.accessTokens.pipelineGrids = gridAccessToken
  global.accessTokens.pipelineWebRecommendation = webRecommendationToken

  global.coveoRecommendationEngine = buildProductRecommendationEngine({
    configuration: {
      organizationId: initialState.env.organizationId, //init.env
      accessToken: webRecommendationToken,
      organizationEndpoints: getOrganizationEndpoints(initialState.env.organizationId),
      pipeline: initialState.env.pipelineWebRecommendation,
      searchHub: initialState.env.pipelineWebRecommendation,
      search: {
        searchHub: initialState.env.pipelineWebRecommendation,
      },
      locale: initialState?.current_locale?.codes?.www || "en_US",
    },
  })

  const fieldsToInclude = [
    "rlx_contenttype",
    "rlx_rmc",
    "rlx_title",
    "rlx_family",
    "rlx_family_facet",
    "rlx_case_title",
    "rlx_newmodelselection",
    "rlx_familycode",
    "rlx_description",
    "rlx_searchimage",
    "rlx_search_section",
    "rlx_search_title",
    "rlx_path",
  ]
  setTimeout(() => {
    const { registerFieldsToInclude } = loadFieldActions(global.coveoHeadlessEngine)
    global.coveoHeadlessEngine.dispatch(registerFieldsToInclude(fieldsToInclude))
  }, 100)

  global.coveoHeadlessEngine = buildSearchEngine({
    configuration: {
      organizationId: initialState.env.organizationId, //init.env
      accessToken: global.accessTokens.pipelineWebsearch,
      organizationEndpoints: getOrganizationEndpoints(initialState.env.organizationId),
      search: {
        searchHub: initialState.env.pipeline,
        preprocessSearchResponseMiddleware: response => {
          resultResponse.push(...response.body.results)
          return response
        },
      },
      preprocessRequest: (request, clientOrigin) => {
        const referrer = getReferrer()
        if (clientOrigin === "analyticsFetch") {
          let body = request.body
          let jsonBody = JSON.parse(body)
          let actionCause = jsonBody["actionCause"]
          if (actionCause === "documentOpen") {
            let documentId = jsonBody.customData.contentIDValue

            let customData = {
              ...jsonBody.customData,
              product_brand: "rolex",
              siteName: "rolex.com",
            }

            let matchedResult = resultResponse.find(result => documentId === result.raw.permanentid)

            if (matchedResult) {
              if (matchedResult.raw.rlx_family_facet) {
                customData = {
                  ...customData,
                  product_variant: matchedResult.raw.rlx_family_facet[0],
                  product_name: matchedResult.title,
                  product_price: null,
                  product_category: matchedResult.raw.rlx_familycode,
                }
              }
            }
            const newJsonBody = {
              ...jsonBody,
              referrer,
              customData,
            }
            const newRequest = {
              ...request,
              body: JSON.stringify(newJsonBody),
            }
            return newRequest
          }
        }
        const body = request.body
        let jsonBody = JSON.parse(body)
        const newJsonBody = {
          ...jsonBody,
          originLevel2: (function () {
            // this is a temporary solution to get the correct originLevel2, we need more info from VTC & Coveo
            if (jsonBody.sourceName?.includes("rlx_catalog")) {
              return "watches"
            } else if (jsonBody.sourceName?.includes("articles")) {
              return "articles"
            } else {
              return jsonBody.originLevel2
            }
          })(),
          referrer,
        }
        const newRequest = {
          ...request,
          body: JSON.stringify(newJsonBody),
        }
        return newRequest
      },
    },
  })
  global.coveoSearchBox = buildSearchBox(global.coveoHeadlessEngine, optionsForSearchBox)
  global.coveoResultList = buildResultsPerPage(global.coveoHeadlessEngine, {
    initialState: { numberOfResults: 1000 },
  })
  global.coveoFacet = buildFacet(global.coveoHeadlessEngine, { options: { field: "source" } })
  global.coveoPager = buildPager(global.coveoHeadlessEngine, { options: { numberOfPages: 3 } }) //todosearch: remove ?
  global.coveoResultTemplatesManager = buildResultTemplatesManager(global.coveoHeadlessEngine)

  // re-execute this function every 24 hours so we keep have a fresh accesstoken
  setInterval(() => {
    coveo([initialState, akamai, ufContext])
  }, 1000 * 60 * 60 * 24)

  return [initialState, akamai, ufContext]
}

async function initialState() {
  return Promise.resolve(
    JSON.parse(document.querySelector("#__INITIAL_STATE")?.textContent ?? null) ??
      Promise.resolve(
        new URL(global.location.pathname.replace(new RegExp(".html$"), "") + ".initial.json", `${global.location.protocol}//${global.location.host}`)
      )
        .then(url => new Request(url))
        .then(request => fetch(request))
        .then(response => response.json())
  )
    .then(initialState =>
      Promise.all([
        Promise.resolve(initialState),
        Promise.all(
          (initialState?.preload ?? []).map(component => {
            try {
              const Component = components[component]
              const lazy = Component?.$$typeof === Symbol.for("react.lazy")
              if (!lazy) return
              Component._init(Component._payload)
            } catch (errOrPromise) {
              if (typeof errOrPromise?.then === "function") return errOrPromise.catch(err => console.error(err))
              else console.error(errOrPromise)
            }
          })
        ),
      ])
    )
    .then(([initialState]) => initialState)
}

async function akamai() {
  const saved = sessionStorage.getItem("reqcontext")
  const url = new URL("/ak-getreqcontext.json", process.env.NODE_ENV === "development" ? "https://www.rolex.com" : global.location.origin)
  return (saved ? Promise.resolve(JSON.parse(saved)) : Promise.reject())
    .catch(() =>
      fetch(url)
        .then(response => response.json())
        .catch(err => {
          console.error(err)
          return { reqcontext: {} }
        })
        .then(({ reqcontext }) => reqcontext)
        .then(reqcontext =>
          Object.keys(reqcontext).reduce((ctx, key) => {
            const cckey = camelCase(key)
            try {
              ctx[cckey] = JSON.parse(reqcontext[key])
            } catch {
              ctx[cckey] = reqcontext[key]
            }
            return ctx
          }, {})
        )
        .then(reqcontext => {
          sessionStorage.setItem("reqcontext", JSON.stringify(reqcontext))
          return reqcontext
        })
    )
    .then(reqcontext => {
      //console.log("ak:ctx(%o)", { cache: !!saved, reqcontext })
      return reqcontext
    })
}
async function main([initialState, akamai, ufContext], forceRender) {
  if (process.env.NODE_ENV === "development") console.debug("initialState/ak/uf, %o", { initialState, akamai, ufContext })

  const hydrate = !forceRender && document.querySelector("meta[name=hydrate]")
  const target = document.querySelector(hydrate ? hydrate.getAttribute("content") : "#root")

  const AppBootstrap = (
    // <SSRProvider>
    <HelmetProvider>
      <Console>
        <UFReactContext context={ufContext}>
          <Suspender>
            <User reqcontext={akamai}>
              <BrowserRouter>
                <Locale initialState={initialState}>
                  <Dictionary initialState={initialState}>
                    <Env initialState={initialState}>
                      <QuantCast initialState={initialState} />
                      <DigitalDataLayer version={document.documentElement.getAttribute("data-version")} initialState={initialState}>
                        <SearchWrapper>
                          <Navigation initialState={initialState}>
                            <Helmet initialState={initialState}>
                              <Viewport>
                                <Theme>
                                  <OverlayProvider>
                                    <QuickViewsProvider>
                                      <StoryProvider>
                                        <WishlistProvider>
                                          <ConfiguratorProvider>
                                            <PanelModalProvider>
                                              <Layout initialState={initialState} />
                                            </PanelModalProvider>
                                          </ConfiguratorProvider>
                                        </WishlistProvider>
                                      </StoryProvider>
                                    </QuickViewsProvider>
                                  </OverlayProvider>
                                </Theme>
                              </Viewport>
                            </Helmet>
                          </Navigation>
                        </SearchWrapper>
                      </DigitalDataLayer>
                    </Env>
                  </Dictionary>
                </Locale>
              </BrowserRouter>
            </User>
          </Suspender>
        </UFReactContext>
      </Console>
    </HelmetProvider>
    // </SSRProvider>
  )

  /**
   * hydratation breaks react fast refresh in dev mode
   */

  if (process.env.NODE_ENV === "production" && hydrate) reactDOM.hydrateRoot(target, AppBootstrap)
  else {
    const url = new URL(global.location)
    if (url.searchParams.get("hydrate") === "1") {
      reactDOM.hydrateRoot(target, AppBootstrap)
    } else createRoot(target).render(AppBootstrap)
  }
}

Promise.all([initialState(), akamai(), uniform()])
  .then(p13n)
  .then(coveo)
  .then(main)
  .catch(err => {
    //TODO ?
    console.error(err)
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
